import { useEffect } from "react";
import { Message, type PluginMessage, UIReadyMessage } from "shared/messages";
import { observer } from "mobx-react-lite";
import { action } from "mobx";
import type { FigmaEditorTheme } from "shared/types";
import { postPluginMessage } from "~/utils/postPluginMessage";
import { store } from "~/store";
import { uiFigma, FigmaSelection } from "~/store/UIFigma";
import type { FigmaMessage } from "~/types";
import { objToCSS } from "~/utils/objToCSS";
import { useRemoveIssueAttachments, useRemoveIssueLinks } from "~/queries/useRemoveIssueAttachments";
import { useRenameIssueAttachments } from "~/queries/useRenameIssueAttachments";

/**
 * Handles standard keydown events for the application, this is only used in VSCode.
 *
 * @param event The keydown event.
 */
function handleKeyDown(event: KeyboardEvent) {
  const ctrlDown = event.metaKey || event.ctrlKey;
  if (ctrlDown) {
    if (event.key === "a") {
      document.execCommand("selectAll");
    } else if (event.key === "z") {
      if (event.shiftKey) {
        document.execCommand("redo");
      } else {
        document.execCommand("undo");
      }
    } else if (event.key === "x") {
      document.execCommand("cut");
    } else if (event.key === "c") {
      document.execCommand("copy");
    } else if (event.key === "v") {
      document.execCommand("paste");
    }
  }
}

/**
 * Handles updating the application when receiving messages from the plugin.
 */
export const PluginMessageHandler = observer(function PluginMessageHandler() {
  const removeIssueAttachments = useRemoveIssueAttachments();
  const removeIssueLinks = useRemoveIssueLinks();
  const renameIssueLinks = useRenameIssueAttachments();

  useEffect(() => {
    const handler = action(async function handler_(event: MessageEvent) {
      /**
       * Ensure the post message is from Figma and not another origin (LIN-34223).
       */
      if (!["https://www.figma.com", window.location.origin].includes(event.origin)) {
        throw new Error(`Invalid origin for post message: ${event.origin}`);
      }

      /**
       * Message from Figma communicating theme variables for hosted UIs.
       */
      if (event.data.figmaMessage?.type === "THEME") {
        const figmaMessage: FigmaMessage["payload"] = event.data.figmaMessage.payload;
        const currentTheme = self.document.documentElement.dataset.theme;

        if (figmaMessage.name !== currentTheme) {
          uiFigma.editorTheme = figmaMessage.name as FigmaEditorTheme;
          self.document.documentElement.dataset.theme = figmaMessage.name;
          const figmaStyles = self.document.getElementById("figma-styles") as HTMLStyleElement;

          figmaStyles.innerHTML = `
            :root {
              ${objToCSS(figmaMessage.variables)}
            }
          `;
        }

        return;
      }

      if (!event.data.pluginMessage) {
        return;
      }

      const pluginMessage: PluginMessage = JSON.parse(event.data.pluginMessage);

      switch (pluginMessage.message) {
        case Message.ATTACHMENTS_WITH_REMOVED_NODES: {
          const attachmentIds = pluginMessage.data.map(attachment => attachment.id);
          await removeIssueAttachments.trigger(attachmentIds);
          break;
        }
        case Message.INIT: {
          await store.setUser(pluginMessage.data.accessToken);

          // Standard key bindings do not work inside of VSCode so we need to create our own – what fun!
          if (pluginMessage.data.isVsCode) {
            self.document.addEventListener("keydown", handleKeyDown);
          }
          break;
        }
        case Message.CURRENT_PAGE: {
          uiFigma.currentPage = pluginMessage.data;
          break;
        }
        case Message.CURRENT_FILE: {
          const { id, editorType, name, fileKey } = pluginMessage.data;

          uiFigma.file = {
            id,
            name,
          };
          uiFigma.fileKey = fileKey;
          uiFigma.editorType = editorType;

          break;
        }
        case Message.ISSUE_LINKS: {
          const { pageId, issueLinks } = pluginMessage.data;
          store.allIssueLinks[pageId] = issueLinks;

          break;
        }
        case Message.SELECTED_NODES:
        case Message.SELECTED_PAGE: {
          uiFigma.selection = new FigmaSelection(pluginMessage.data);
          break;
        }
        case Message.REMOVED_ISSUE_LINK_NODES: {
          const removedIssueLinks = pluginMessage.data;

          await removeIssueLinks.trigger(removedIssueLinks);
          break;
        }
        case Message.RENAMED_ISSUE_LINK_NODES: {
          const renamedIssueLinks = pluginMessage.data;

          await renameIssueLinks.trigger({
            issueLinks: renamedIssueLinks,
          });
          break;
        }
        case Message.UPDATED_ALL_ISSUE_LINKS: {
          store.initializingIssueLinks = false;
          break;
        }
        default:
          break;
      }
    });

    window.addEventListener("message", handler);

    // Signal the plugin that the UI is ready
    postPluginMessage(new UIReadyMessage());

    return () => {
      window.removeEventListener("message", handler);
    };
  }, []);

  return null;
});
