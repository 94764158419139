import memoize from "lodash/memoize";
import { css } from "styled-components";
import { Text } from "../components/Text";
import { notReachable } from "../errors/UnreachableCaseError";
import { Browser } from "../utils/browser";
import { highlight, transitionSpeed, color, shadow } from "./styled";
import { fontSize, fontWeight, thinBorder, thinBorderThemed } from "./mixins";

/** The kinds of buttons */
export type ButtonKind =
  | "primary"
  | "secondary"
  | "link"
  | "dangerous"
  | "borderless"
  | "dangerous-borderless"
  | "ghost"
  | "card"
  | "circle";

/** The  button sizes */
export type ButtonSize = "small" | "medium" | "normal" | "large";

const rootCss = css`
  display: inline-flex;
  // because we use an inline display we need to specify "top" here so that buttons don't try to incorrectly align with
  // texts baseline which can throw alignment off even in centered flex containers
  vertical-align: top;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-shrink: 0;
  margin: 0;
  border-radius: 5px;
  font-weight: ${fontWeight("medium")};
  line-height: normal;
  transition-property: border, background-color, color, opacity;
  transition-duration: ${transitionSpeed("highlightFadeOut")};
  user-select: none;
  -webkit-app-region: no-drag;

  svg {
    transition-property: fill;
    transition-duration: ${transitionSpeed("highlightFadeOut")};
  }

  // prettier-ignore
  &:not(:disabled):${highlight} {
    transition-duration: ${transitionSpeed("highlightFadeIn")};
    svg {
      transition-duration: ${transitionSpeed("highlightFadeIn")};
    }
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
`;

const kindCss = memoize(
  function kindCss_({ kind, active = false }: { kind: ButtonKind; active?: boolean }) {
    switch (kind) {
      case "primary":
        return css`
          border: ${props => thinBorder(props.theme.color.controlPrimaryHover)};
          box-shadow: ${shadow("low")};
          background-color: ${active ? color("controlPrimaryHover") : color("controlPrimary")};
          color: ${color("controlLabel")};
          ${buttonHighlightSelector} {
            background-color: ${color("controlPrimaryHover")};
          }
          &:focus-visible {
            outline-offset: 2px;
          }
        `;

      case "secondary":
        return css`
          border: ${thinBorderThemed("bgBorderSolid")};
          box-shadow: ${shadow("low")};
          background-color: ${active ? color("controlSecondaryHover") : color("controlSecondary")};
          color: ${active ? color("labelTitle") : color("labelBase")};
          ${buttonHighlightSelector} {
            background-color: ${color("controlSecondaryHover")};
          }
          &:disabled {
            color: ${color("labelMuted")};
          }
        `;
      case "ghost":
        return css`
          border: 1px dashed ${props => props.theme.highlightVariant(props.theme.color.bgBorderSolid)};
          background-color: transparent;
          color: ${color("labelBase")};
          ${buttonHighlightSelector} {
            color: ${color("labelTitle")};
            background-color: ${color("controlTertiaryHover")};
            ${Text} {
              color: ${color("labelTitle")};
            }
            svg {
              fill: ${color("labelTitle")};
            }
          }
          &:focus-visible {
            outline-offset: 2px;
          }
        `;

      case "borderless":
        return css`
          border: ${thinBorder("transparent")};
          background-color: transparent;
          color: ${color("labelBase")};
          ${buttonHighlightSelector} {
            color: ${color("labelTitle")};
            background-color: ${color("controlTertiaryHover")};
            ${Text} {
              color: ${color("labelTitle")};
            }
            svg:not(.color-override) {
              fill: ${color("labelTitle")};
            }
          }
        `;

      case "dangerous-borderless":
        return css`
          border: ${thinBorder("transparent")};
          background-color: transparent;
          color: ${color("redText")};
          ${buttonHighlightSelector} {
            color: ${color("redText")};
            background-color: ${color("controlTertiaryHover")};
            ${Text} {
              color: ${color("redText")};
            }
            svg:not(.color-override) {
              fill: ${color("redText")};
            }
          }
          &:disabled {
            color: ${color("labelBase")};
          }
        `;

      case "link":
        return css`
          border: ${thinBorder("transparent")};
          box-shadow: none;
          background-color: transparent;
          color: ${active ? color("labelTitle") : color("labelBase")};
          ${buttonHighlightSelector} {
            color: ${color("labelTitle")};
            svg {
              fill: ${color("labelTitle")};
            }
          }
        `;

      case "dangerous":
        return css`
          --focus-ring-color: ${color("redBaseHover")};
          --focus-ring-outline: var(--focus-ring-width) solid ${color("redBaseHover")};
          border: ${props => thinBorder(props.theme.color.redBaseHover)};
          box-shadow: ${shadow("low")};
          background-color: ${active ? color("redBaseHover") : color("redBase")};
          color: ${color("controlLabel")};
          ${buttonHighlightSelector} {
            background-color: ${color("redBaseHover")};
          }
          &:focus-visible {
            outline-offset: 2px;
          }
        `;

      case "card":
        return css`
          width: 100%;
          flex-direction: column;
          gap: 4px;
          border: ${thinBorderThemed("bgBorderSolid")};
          background-color: ${active ? color("bgShade") : "transparent"};
          color: ${active ? color("labelBase") : color("labelMuted")};
          svg {
            fill: ${active ? color("labelBase") : color("labelMuted")};
          }
          ${buttonHighlightSelector} {
            ${active
              ? ""
              : css`
                  background-color: ${color("bgShadeHover")};
                  border-color: ${color("bgBorderSolidHover")};
                `}
            color: ${color("labelBase")};
            svg {
              fill: ${color("labelBase")};
            }
          }
          &:disabled {
            color: ${color("labelMuted")};
          }
        `;

      case "circle":
        return css`
          border-radius: 50%;
          box-shadow: none;
          color: ${active ? color("labelTitle") : color("labelBase")};
          &:focus-visible {
            outline-offset: 2px;
          }
        `;

      default:
        throw notReachable(kind);
    }
  },
  ({ kind, active = false }: { kind: ButtonKind; active?: boolean }) => `${kind}-${active}`
);

function sizeCss({ size, onlyIcon, isCircle }: { size: ButtonSize; onlyIcon?: boolean; isCircle?: boolean }) {
  let length;
  switch (size) {
    case "small":
      length = isPureTouch ? 28 : 24;
      return css`
        ${isCircle
          ? css`
              width: ${length}px;
            `
          : css`
              min-width: ${length}px;
            `}
        height: ${length}px;
        padding: ${onlyIcon ? "0 2px" : "0 8px"};
        font-size: ${fontSize("mini")};
      `;
    case "medium":
      length = isPureTouch ? 32 : 28;
      return css`
        ${isCircle
          ? css`
              width: ${length}px;
            `
          : css`
              min-width: ${length}px;
            `}
        height: ${length}px;
        padding: ${onlyIcon ? "0 2px" : "0 14px"};
        font-size: ${fontSize("mini")};
      `;
    case "normal":
      length = isPureTouch ? 36 : 32;
      return css`
        ${isCircle
          ? css`
              width: ${length}px;
            `
          : css`
              min-width: ${length}px;
            `}
        height: ${length}px;
        padding: ${onlyIcon ? "0 2px" : "0 14px"};
        font-size: ${fontSize("small")};
      `;
    case "large":
      return css`
        ${isCircle
          ? css`
              width: 44px;
            `
          : css`
              min-width: 32px;
            `}
        height: 44px;
        padding: ${onlyIcon ? "0 2px" : "0 21px"};
        font-size: ${fontSize("small")};
      `;
    default:
      throw notReachable(size);
  }
}

function iconCss(props: {
  size: ButtonSize;
  $onlyIcon?: boolean;
  monotoneIcon?: boolean;
  $rightAlign?: boolean;
  icon?: React.ReactNode;
}) {
  return props.icon !== undefined
    ? `> *:${props.$rightAlign ? "last" : "first"}-child {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      ${
        props.size === "medium"
          ? `max-width: 16px;
        max-height: 16px;
        ${
          props.$onlyIcon
            ? `&:not(:only-child) { margin-${props.$rightAlign ? "left" : "right"}: 8px; }`
            : `margin-${props.$rightAlign ? "left" : "right"}: 8px;`
        }`
          : props.size === "normal"
          ? `max-width: 18px;
        max-height: 18px;
        ${
          props.$onlyIcon
            ? `&:not(:only-child) { margin-${props.$rightAlign ? "left" : "right"}: 10px; }`
            : `margin-${props.$rightAlign ? "left" : "right"}: 10px;`
        }`
          : props.size === "small"
          ? `max-width: 16px;
        max-height: 16px;
        ${
          props.$onlyIcon
            ? `&:not(:only-child) { margin-${props.$rightAlign ? "left" : "right"}: 6px; }`
            : `margin-${props.$rightAlign ? "left" : "right"}: 6px;`
        }`
          : ""
      };
      transition-property: fill, stroke;
      transition-duration: ${transitionSpeed("highlightFadeOut")};
      svg {
        ${props.monotoneIcon ? `fill: currentColor;` : ""}
      }
    }
  &:enabled:${highlight} svg {
    transition-duration: ${transitionSpeed("highlightFadeIn")};
  }`
    : "";
}

/**
 * Mixin selector for when the button is highlighted OR active from a menu thats open
 */
export function buttonHighlightSelector() {
  return `&:not(:disabled, [data-disabled="true"]):${highlight},
  [data-menu-open="true"] > &,
  &[data-menu-open="true"]`;
}

/**
 * Button styles to mix in for other components that wants to look like a button.
 */
export const buttonStyles = {
  rootCss,
  kindCss,
  sizeCss,
  iconCss,
};

const isPureTouch = Browser.isPureTouchDevice;
