import * as React from "react";
import {
  createPopper,
  type Instance as PopperInstance,
  type Options,
  type Placement,
  type VirtualElement as PopperVirtualElement,
  type Modifier,
  type PositioningStrategy,
  type Boundary,
  type RootBoundary,
  type Padding,
  type Context,
} from "@popperjs/core";
import { css } from "styled-components";
import { getTransformForPlacement } from "./getTransformForPlacement";
import { maxSize, applyMaxSize, alwaysApplyMaxSize } from "./modifiers";

/** Popover options (Popper.js) */
export type PopoverOptions = Partial<Options> & {
  /**
   * "always" will always set the max-height on the popover.
   * "never" will never set it at all and it might overflow the viewport.
   * "default" will only set it if the content does overflow, otherwise does nothing.
   */
  maxSizeModifier?: "always" | "never" | "default";
  /**
   * Modifiers to pass to max-size modifier.
   */
  maxSizeOptions?: Partial<{
    strategy: PositioningStrategy;
    boundary: Boundary;
    rootBoundary: RootBoundary;
    elementContext: Context;
    altBoundary: boolean;
    padding: Padding;
  }>;

  /**
   * Whether the popover should hide when the target is no longer visible in the viewport.
   * Defaults to true
   */
  hideWithTarget?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  /** We make strategy required (it otherwise defaults to "fixed", which causes bugs inside css containers) */
  strategy: PositioningStrategy;
};
/** Popover placement. */
export type PopoverPlacement = Placement;
/** Popover virtual element. Allows anchoring the popper without needing a mounted DOM element */
export type VirtualElement = PopperVirtualElement;

/** The Popover props */
export type Props = {
  /** Target element to which the popover positioned against. Allows both HTML elements and virtual elements. */
  targetRef: React.RefObject<HTMLElement | VirtualElement>;
  /** The reference to the popover element. */
  popoverRef: React.RefObject<HTMLElement>;
  /** Popper.js options to override the default ones. */
  options?: PopoverOptions;
  /** Whether the popover is initially open. */
  initiallyOpen?: boolean;
};

/** Default offset for popovers */
export const DEFAULT_POPOVER_OFFSET = 4;

/**
 * Create a Popper.js powered popover with custom data.
 *
 * @param props The properties to use.
 */
export function usePopover({ targetRef, popoverRef, initiallyOpen, options }: Props) {
  const [lifecycle, setLifecycle] = React.useState<PopoverLifecycle>(PopoverLifecycle.closed);
  const [placement, setPlacement] = React.useState(options?.placement ?? "top-start");

  const popper = React.useRef<PopperInstance>();
  const mounted = React.useRef<boolean>(true);

  const onShow = React.useCallback(() => {
    if (mounted.current) {
      options?.onOpen?.();
      setLifecycle(PopoverLifecycle.open);
    }
  }, [options?.onOpen]);

  const onHide = React.useCallback(() => {
    if (mounted.current) {
      setLifecycle(l => (l === PopoverLifecycle.closed ? l : PopoverLifecycle.closing));
    }
  }, []);

  const handleClosed = React.useCallback(() => {
    if (mounted.current) {
      setLifecycle(PopoverLifecycle.closed);
      options?.onClose?.();
    }
  }, [options?.onClose]);

  React.useLayoutEffect(() => {
    if (lifecycle === PopoverLifecycle.closed) {
      popper.current?.destroy();
      popper.current = undefined;
    } else if (!popper.current && targetRef.current && popoverRef.current) {
      const { onFirstUpdate } = options ?? {};
      popper.current = createPopper(targetRef.current, popoverRef.current, {
        placement,
        strategy: options?.strategy ?? "fixed",
        modifiers: createModifiers(placement, options),
        // Ensure we use the right placement to calculate transformOrigin
        onFirstUpdate(state) {
          onFirstUpdate?.(state);
          if (state.placement) {
            setPlacement(state.placement);
          }
        },
      });
    }
  }, [lifecycle]);

  React.useLayoutEffect(() => {
    if (popper.current) {
      void popper.current.setOptions({
        placement,
        modifiers: createModifiers(placement, options),
      });
    }
  }, [placement]);

  React.useEffect(() => {
    const unregister = registerPopover(() => {
      handleClosed();
    });
    mounted.current = true;
    if (initiallyOpen) {
      onShow();
    }

    return () => {
      unregister();
      mounted.current = false;
    };
  }, []);

  React.useLayoutEffect(() => {
    if (options?.placement) {
      setPlacement(options.placement);
    }
  }, [options?.placement]);

  return {
    onShow,
    onHide,
    ref: popper,
    popoverProps: {
      lifecycle,
      onClosed: handleClosed,
      transformOrigin: getTransformForPlacement(popper.current?.state.placement ?? placement),
    },
  };
}

/** Style for poppers that hides them when target is scrolled out of viewport */
export const popperStyles = css`
  [data-popper-reference-hidden] & {
    opacity: 0 !important;
    pointer-events: none;
  }
`;

/** The lifecycle of the popover. */
export enum PopoverLifecycle {
  /** The popover is closed. */
  closed,
  /** The popover is open. This also includes when then popover is animating to its open state. */
  open,
  /** The popover is playing the closing animation, but has not yet fully closed. */
  closing,
}

// - Track all popovers to allow closing popovers from anywhere.

let popoverId = 0;
const popovers = new Map<number, () => void>();

const registerPopover = (close: () => void) => {
  const id = popoverId++;
  popovers.set(id, close);
  return () => popovers.delete(id);
};
type OffsetModifier = Modifier<"offset", { offset: [number, number] }>;

function createModifiers(placement: Placement, options?: PopoverOptions) {
  const { modifiers } = options ?? {};
  const offsetModifier = adjustOffset(
    placement,
    options?.placement ?? "top-start",
    (modifiers?.find(m => m.name === "offset") ?? {
      name: "offset",
      options: {
        offset: [0, DEFAULT_POPOVER_OFFSET],
      },
    }) as OffsetModifier
  );

  const result = (modifiers ?? []).filter(m => m.name !== "offset");
  result.push(offsetModifier);

  if (options?.maxSizeModifier === "never") {
    // Skip maxSize modifier
  } else {
    if (options?.maxSizeModifier === "always") {
      result.push(alwaysApplyMaxSize);
    }

    result.push(
      {
        ...maxSize,
        options: Object.assign(
          {
            padding: 16, // Default padding, so the popover doesn't touch the edges
          },
          options?.maxSizeOptions
        ),
      },
      applyMaxSize
    );
  }

  result.push({ name: "hide", enabled: options?.hideWithTarget !== false });

  return result;
}

// Adjust offset modifier to account for changed placements
function adjustOffset(placement: Placement, originalPlacement: Placement, offsetModifier: OffsetModifier) {
  if (placement === originalPlacement || !offsetModifier.options?.offset?.[0]) {
    return offsetModifier;
  }
  const adjusted = {
    name: "offset",
    options: {
      offset: [-offsetModifier.options.offset[0], offsetModifier.options.offset[1]],
    },
  };
  switch (originalPlacement) {
    case "bottom-end":
    case "top-end":
      switch (placement) {
        case "bottom-start":
        case "top-start":
          return adjusted;
        default:
          break;
      }
      break;
    case "bottom-start":
    case "top-start":
      switch (placement) {
        case "bottom-end":
        case "top-end":
          return adjusted;
        default:
          break;
      }
      break;
    default:
      break;
  }
  return offsetModifier;
}

/**
 * Closes all open popovers.
 */
export const closeAllPopovers = () => {
  popovers.forEach(close => close());
};
