/**
 * A consistent hashing function that turns a string into a positive number. Should not be used for cryptography.
 */
export function numericHash(value: string): number {
  let hash = 5381;
  let i = value.length;

  while (i) {
    hash = (hash * 33) ^ value.charCodeAt(--i);
  }
  return hash >>> 0;
}
