// @ts-ignore Fixes web build
import { css, type ThemedStyledProps } from "styled-components";
import { ColorConverter } from "../utils/ColorConverter";
import type { Color, Theme } from "./Theme";

/**
 * Truncate text.
 */
export const truncate = () => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Truncate multiline text.
 */
export const truncateMultiline = (lines: number) => `
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${lines};
  overflow: hidden;
  overflow-wrap: anywhere;
`;

/**
 * A style helper that gives the element more hit-area in all directions.
 *
 * @param pixels The numbers of pixels of hit are to give the element.
 */
export function moreHitArea(pixels: number): string;
/**
 * A style helper that gives the element more hit-area.
 *
 *
 * @param top How many pixels of hit are to add on top.
 * @param right How many pixels of hit are to add on the right.
 * @param bottom How many pixels of hit are to add on the bottom.
 * @param left How many pixels of hit are to add on the left.
 * @param addPositioning Whether to change the positioning of the element to be relative. This defaults to true. Change it if this element is already absolute/fixed
 */
export function moreHitArea(top: number, right: number, bottom: number, left: number, addPositioning?: boolean): string;
export function moreHitArea(
  pixelsOrTop: number,
  right?: number,
  bottom?: number,
  left?: number,
  addPositioning: boolean = true
): string {
  return `
  ${addPositioning ? "position: relative;" : ""}
  &::before {
    position: absolute;
    content: "";
    top: -${pixelsOrTop}px;
    right: -${right ?? pixelsOrTop}px;
    left: -${left ?? pixelsOrTop}px;
    bottom: -${bottom ?? pixelsOrTop}px;
  }
`;
}

/**
 * Hide scrollbars for the component
 *
 * @description !important is used to override behavior even when layoutScrollbarObtrusive is applied
 */
export const hideScrollbars = () => `
  -ms-overflow-style: none !important;  // IE 10+
  overflow: -moz-scrollbars-none !important;  // Firefox
  scrollbar-width: none !important; // Firefox
  &::-webkit-scrollbar {
    display: none;
  }
`;

/**
 * Base styles for gradient filled text.
 *
 * @note You should put this _after_ declaring `background`, otherwise these styles will be overriden.
 */
export const gradientText = () => `
   /* Ensure the gradient repeats across line breaks */
   box-decoration-break: clone;
   -webkit-box-decoration-break: clone;
   background-clip: text;
   -webkit-background-clip: text;
   text-fill-color: transparent;
   -webkit-text-fill-color: transparent;
   color: unset;
 `;

/** Reset UA button styling so that it looks like regular text. */
export const buttonReset = () => `
   appearance: none;
   background: none;
   border: none;
   padding: 0;
   margin: 0;
   font: inherit;
   color: inherit;
   -webkit-tap-highlight-color: transparent;
 `;

/** Reset UA <select> styling */
export const selectReset = () => `
  user-select: none;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  -webkit-tap-highlight-color: transparent;
`;

/** Dynamic tracking letter spacing for Inter font */
export const interTracking = (size: number) => {
  // Rasmus recommends these values for Inter dynamic tracking
  // formula: https://rsms.me/inter/dynmetrics
  const a = -0.0223;
  const b = 0.185;
  const c = -0.1745;
  const spacing = Number((a + b * Math.pow(Math.E, c * size)).toFixed(3));

  return spacing + "px";
};

/** The named font-sizes supported by the app. */
export type FontSize =
  | "micro"
  | "microPlus"
  | "mini"
  | "miniPlus"
  | "small"
  | "smallPlus"
  | "regular"
  | "regularPlus"
  | "large"
  | "largePlus"
  | "title1"
  | "title2"
  | "title3";

/** Returns the font size variable of a given named font-size. */
export function fontSize(type: FontSize): string {
  return `var(--font-size-${type})`;
}

/** The named font-weights supported by the app. */
export type FontWeight = "light" | "normal" | "medium" | "semibold" | "bold";

/** Returns the font weight variable of a given weight. */
export function fontWeight(weight: FontWeight): string {
  return `var(--font-weight-${weight})`;
}

/**
 * Returns a thin (half-pixel) border style.
 *
 * @param color The color of the border.
 */
export function thinBorder(color: string): string {
  return `${thinPixel()} solid ${color}`;
}

/**
 * Returns a thin (half-pixel) border style.
 *
 * @param colorName The name of a color from the theme.
 */
export function thinBorderThemed(
  colorName: keyof Pick<Color, "bgBorder" | "bgBorderSolid" | "bgBorderFaint">,
  borderStyle: "solid" | "dashed" = "solid"
) {
  return (props: ThemedStyledProps) =>
    `${retinaDisplay ? "0.5" : "1"}px ${borderStyle} ${thinBorderColorThemed(colorName)(props)}`;
}
/**
 * Returns the color for a thin (half-pixel) border.
 *
 * @param colorName The name of a color from the theme.
 */
export function thinBorderColorThemed(colorName: keyof Pick<Color, "bgBorder" | "bgBorderSolid" | "bgBorderFaint">) {
  return (props: ThemedStyledProps) =>
    `${props.theme.color[retinaDisplay ? (`${colorName}Thin` as const) : (`${colorName}` as const)]}`;
}

/** Returns a thin (half-pixel) value if display is retina */
export function thinPixelValue() {
  return retinaDisplay ? 0.5 : 1;
}

/** Returns a thin (half-pixel) value if display is retina */
export function thinPixel() {
  return retinaDisplay ? "0.5px" : "1px";
}

/** Returns the default distance a model is offset from the top and bottom of the screen. */
export function modalMarginValue() {
  return 0.13;
}

/** Returns the default distance a model is offset from the top and bottom of the screen. */
export function modalMargin() {
  return "13vh";
}

/** Returns a transition that can be used to expand a modal consistently. */
export function modalExpandTransition(property: string) {
  return `
  transition: 300ms;
  transition-property: ${property};
  transition-timing-function: cubic-bezier(0.43, 0.07, 0.59, 0.94);
  `;
}

/**
 * Uses the ::before pseudo element to mask out space for a linear gradient.
 * Works even if your element background has opacity.
 */
export const gradientBorder = (size: number, gradient: string, applyOnAfter?: boolean) => `
  position: relative;

  &::${applyOnAfter ? "after" : "before"} {
    content: "";
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    padding: ${size}px;
    background: ${gradient};

    mask: linear-gradient(black, black) content-box, linear-gradient(black, black);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

/** If the user is currently on a retina display */
export const retinaDisplay =
  (typeof window !== "undefined" &&
    window.matchMedia?.("only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi)")
      .matches) ||
  false;

type NegativeMarginDirection = "top" | "right" | "bottom" | "left" | "all";

/**
 * A style helper that pads the element, then offsets the padding with negative
 * margins. Useful for showing overflowing shadows "outside" of a scrollable
 * element.
 *
 * @param space The amount of space to pad and offset.
 * @param direction The direction(s) to apply the negative margin. Defaults to
 * "all".
 */
export function negativeMarginSpace(
  space: number,
  direction: NegativeMarginDirection | NegativeMarginDirection[] = "all"
) {
  if (direction === "all") {
    return `
      margin: -${space}px;
      padding: ${space}px;
    `;
  }

  function negativeMarginDirection(dir: NegativeMarginDirection) {
    return `
      margin-${dir}: -${space}px;
      padding-${dir}: ${space}px;
    `;
  }

  if (Array.isArray(direction)) {
    return direction.map(negativeMarginDirection).join("\n");
  }

  return negativeMarginDirection(direction);
}

/**
 *
 * @param activeInput Whether the color is for an active input or not. Active inputs should have a more pronounced selection color.
 * @returns A color for selected text background as a css string.
 */
export const selectionColor = (activeInput: boolean) => (props: ThemedStyledProps) =>
  ColorConverter.toCss(
    props.theme.colorFormat,
    ColorConverter.adjustTo(
      ColorConverter.fromCss(activeInput ? props.theme.focusColor : props.theme.color.labelMuted),
      {
        a: activeInput ? 0.4 : 0.2,
      }
    )
  );

/**
 * Insets the `outline` property to make room for the focus ring. Useful if the focus ring is cutoff by `overflow: hidden`.
 */
export const insetOutline = css`
  outline-offset: calc(-1 * var(--focus-ring-width));
`;
/**
 * Adds the default focus outline
 */
export const focusOutline = css`
  outline: var(--focus-ring-outline);
`;

/**
 * Base Linear gradient background.
 */
export const baseBgGradient = (theme: Theme) =>
  theme.isDark
    ? css`
        background: linear-gradient(180deg, ${theme.color.bgBase} 0%, ${theme.color.bgSub} 50%);
      `
    : css`
        background: ${theme.color.bgBase};
      `;
