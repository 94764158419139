import { useState } from "react";
import type { Attachment, Issue } from "shared/types";
import type { Variables as RequestVariables } from "graphql-request";
import useSWRMutation from "swr/mutation";
import { runInAction } from "mobx";
import { Message } from "shared/messages";
import type { IssueLinksMap } from "shared/IssueLink";
import { store } from "~/store";
import type { uiFigma } from "~/store/UIFigma";
import { fetchLinear } from "~/utils/fetchLinear";
import type { FetchedIssue } from "~/types";
import { useMessageEventListener } from "~/hooks/useMessageEventListener";
import { flattenFetchedIssue } from "~/utils/flattenFetchedIssue";
import { IssueFragment } from "./fragments/IssueFragment";

const LinkIssue = `
  mutation LinkIssue(
    $issueId: String!,
    $subtitle: String,
    $title: String!,
    $url: String!
  ) {
    attachmentCreate(
      input: {
        groupBySource: true,
        issueId: $issueId,
        subtitle: $subtitle,
        title: $title,
        url: $url,
      }
    ) {
      attachment {
        id
        issue {
          ${IssueFragment}
        }
        title
        url
      }
    }
  }
`;

interface LinkIssueReturn {
  attachmentCreate: {
    attachment: Attachment & {
      issue: FetchedIssue;
    };
  };
}

interface LinkIssueMutationArgs extends RequestVariables {
  issueId: Issue["id"];
  subtitle?: string;
  title: string;
  url: string;
}

async function linkIssue(args: LinkIssueMutationArgs): Promise<LinkIssueReturn> {
  const response = await fetchLinear<LinkIssueReturn>([LinkIssue, args]);
  return response;
}

interface LinkIssueArgs {
  file: typeof uiFigma.file;
  fileKey: typeof uiFigma.fileKey;
  issue: Issue;
  selection: typeof uiFigma.selection;
  page: typeof uiFigma.currentPage;
}

/**
 * Mutation for linking an issue to a selection of Figma nodes by creating an
 * attachment for each node on the issue.
 *
 * Since the issue to link isn't known at the time of hook invocation, it's
 * supplied as an argument when the mutation is triggered.
 */
export function useLinkIssue({
  onIssueLinked,
}: {
  onIssueLinked?: (linkedIssue: Issue, issueLinks: IssueLinksMap) => void;
} = {}) {
  const [linkedIssue, setLinkedIssue] = useState<Issue | null>(null);

  useMessageEventListener(
    linkedIssue && onIssueLinked
      ? pluginMessage => {
          if (pluginMessage.message === Message.ISSUE_LINKS) {
            const { issueLinks } = pluginMessage.data;
            onIssueLinked(linkedIssue, issueLinks);
            setLinkedIssue(null);
          }
        }
      : undefined
  );

  const mutation = useSWRMutation(LinkIssue, async (_query, { arg }: { arg: LinkIssueArgs }) => {
    const { file, fileKey, issue, selection, page } = arg;

    const nodes = selection.isPage || selection.nodes.length === 0 ? [page] : selection.nodes;

    // Title for the attachment is "{name of Figma file}/{name of current Figma page}"
    const title = `${file.name}/${page.name}`;
    const url = new URL(`https://www.figma.com/file/${fileKey}/${encodeURIComponent(page.name)}`);

    const nodeLinkRequests = nodes.map(node => {
      const urlParams = new URLSearchParams({
        "node-id": node.id.replace(/:/g, "-"),
      });
      url.search = urlParams.toString();

      return linkIssue({
        issueId: issue.id,
        subtitle: node.type !== "PAGE" ? node.name : undefined,
        title,
        url: url.toString(),
      });
    });

    const responses = await Promise.all(nodeLinkRequests);

    if (!responses) {
      throw new Error("Unable to create new issue");
    }

    const fetchedIssue = responses[responses.length - 1]?.attachmentCreate.attachment.issue;

    if (!fetchedIssue) {
      throw new Error("Unable to fetch issue after linking");
    }

    const latestIssue = flattenFetchedIssue(fetchedIssue);
    const attachmentsCreated = responses.map(response => response.attachmentCreate.attachment);

    runInAction(() => {
      return store.linkIssue(latestIssue, attachmentsCreated);
    });

    setLinkedIssue(latestIssue);

    return latestIssue;
  });

  return {
    ...mutation,
    isLinkingIssue: Boolean(linkedIssue),
  };
}
