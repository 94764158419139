import type { Color, Theme } from "../styles/Theme";
import { ColorConverter } from "./ColorConverter";

/**
 * Returns a theme object based on the theme input provided.
 *
 * @param customColor Custom colors to override base theme
 * @return Custom theme based on the colors provided
 */
export namespace ThemeHelper {
  /**
   * Unmemoized version of the function which returns a theme object based on theme input.
   */
  export function generateThemeUnmemoized(input: ThemeInput): Theme {
    const bgBase = input.base;
    const light = bgBase[0] > 50;
    const veryLight = light && bgBase[0] > 98 && bgBase[1] < 8;
    const veryDark = !light && bgBase[0] < 5 && bgBase[1] < 8;

    const bgContrastBoost = ((light ? -1 : 1) * input.contrast) / 30;

    const bg = (
      base: ColorConverter.LCH,
      adjustments: ColorConverter.LCHAdjustments,
      adjustTo?: ColorConverter.LCHAdjustments
    ) => {
      const retVal = ColorConverter.adjust(
        base,
        mapValues(adjustments, v => v && v * bgContrastBoost)
      );
      return adjustTo ? ColorConverter.adjustTo(retVal, adjustTo) : retVal;
    };
    const controlContrastBoost = ((light ? -0.8 : 1) * input.contrast) / 70;
    const control = (
      base: ColorConverter.LCH,
      adjustments: ColorConverter.LCHAdjustments,
      adjustTo?: ColorConverter.LCHAdjustments
    ) => {
      const retVal = ColorConverter.adjust(
        base,
        mapValues(adjustments, v => v && v * controlContrastBoost)
      );
      return adjustTo ? ColorConverter.adjustTo(retVal, adjustTo) : retVal;
    };

    const borderContrastBoost = ((light ? -0.9 : 0.8) * (input.contrast + Math.max(input.contrast - 30, 0) * 0.1)) / 10;
    const border = (
      base: ColorConverter.LCH,
      adjustments: ColorConverter.LCHAdjustments,
      adjustTo?: ColorConverter.LCHAdjustments
    ) => {
      const retVal = ColorConverter.adjust(base, {
        ...adjustments,
        l: adjustments.l && adjustments.l * borderContrastBoost,
        c: (adjustments.c ?? 0) * borderContrastBoost,
      });
      return adjustTo ? ColorConverter.adjustTo(retVal, adjustTo) : retVal;
    };

    const textContrastBoost = ((light ? -1 : 1) * (3 + (100 - input.contrast) / 70)) / 4;
    const text = (
      base: ColorConverter.LCH,
      adjustments: ColorConverter.LCHAdjustments,
      adjustTo?: ColorConverter.LCHAdjustments
    ) => {
      const retVal = ColorConverter.adjust(ColorConverter.getTextColor(base), {
        ...adjustments,
        l: adjustments.l && adjustments.l * textContrastBoost,
      });
      return adjustTo ? ColorConverter.adjustTo(retVal, adjustTo) : retVal;
    };

    const shadowContrastBoost = Math.max(1, 1 + Math.max(input.contrast - 30, 0) / (light ? 50 : 10));

    const shadow = (a: number) => {
      return ColorConverter.toCss(input.colorFormat, [0, 0, 0, a * shadowContrastBoost]);
    };

    const textMultiplier = (1 + Math.abs(bgBase[0] - 50) / 50) / 2;

    const bgBaseHover = bg(bgBase, light ? { l: 1.25 } : { l: veryDark ? 2 : 1.5, c: 1 });

    const bgSub = bg(bgBase, light ? { l: 5 } : { l: -2, c: -2 });
    const bgSubHover = bg(bgSub, light ? { l: 2 } : { l: veryDark ? 3 : 2.5, c: 3 });

    const bgShade = bg(bgBase, veryLight ? { l: 4.5, c: -2 } : light ? { l: -5 } : { l: 5, c: 2 });
    const bgShadeHover = bg(bgShade, light ? { l: 2 } : { l: veryDark ? 3 : 2.5, c: 1 });

    const bgBorder = border(bgBase, { l: 3.5, c: 1 });
    const bgBorderHover = border(bgBorder, { l: 1 });

    const bgBorderThin = border(bgBase, { l: 4, c: 1 });

    const bgBorderFaint = border(bgBase, light ? { l: 1.5, c: 1 } : { l: 1.75, c: 1 });
    const bgBorderFaintHover = border(bgBorderFaint, { l: 1 });
    const bgBorderFaintThin = border(bgBase, light ? { l: 2.5, c: 1 } : { l: 2, c: 1 });

    const bgBorderSolid = border(bgBase, { l: 5, c: 1 });
    const bgBorderSolidHover = border(bgBase, { l: 6, c: 1 });
    const bgBorderSolidThin = border(bgBase, { l: 5, c: 1 });

    const bgSelected = ColorConverter.mix(bgBase, input.accent, (1 + bgBase[1] / 30) * (light ? 0.18 : 0.05));
    const bgSelectedHover = bg(bgSelected, light ? { l: 2 } : { l: 2.5, c: 2 });

    const labelTitle = text(bgBase, { l: light ? -10 * textMultiplier : 10 }, { c: 0 });
    const labelBase = text(bgBase, { l: (light ? -20 : -10) * textMultiplier, c: 1 });
    const labelMuted = text(bgBase, { l: (light ? -40 : -40) * textMultiplier, c: 1 });
    const labelFaint = text(bgBase, { l: (light ? -66 : -66) * textMultiplier, c: 1 });
    const labelLink = text(bgBase, { l: (light ? -45 : -45) * textMultiplier }, { h: input.accent[2], c: 70 });

    const controlPrimary = input.accent;
    const controlSecondary = control(bgBase, light ? { l: -6 } : { l: 15, c: 6 });

    const controlTertiary = control(bgBase, light ? {} : { l: 5, c: 1 });
    const controlTertiarySelected = control(bgBase, light ? { l: 15, c: -3 } : { l: 10, c: 5 });

    const naiveFocusColor = input.baseTheme ? ColorConverter.fromCss(input.baseTheme.color.focusColor) : input.accent;

    const accentCanBeUsedForFocus =
      naiveFocusColor[1] > 50 && (light ? naiveFocusColor[0] < 90 : naiveFocusColor[0] > 30);
    const focusColor: ColorConverter.LCH = accentCanBeUsedForFocus
      ? naiveFocusColor
      : ColorConverter.adjustTo(naiveFocusColor, light ? { l: 70, c: 90 } : { l: 50, c: 120 });

    const purpleBase: ColorConverter.LCH = [48, 59.31, 288.43];
    const blueBase: ColorConverter.LCH = [80, 70, 267];
    const tealBase: ColorConverter.LCH = [67.5, 45, 210];
    const greenBase: ColorConverter.LCH = [60, 64.37, 141.95];
    const yellowBase: ColorConverter.LCH = [80, 90, 85];
    const orangeBase: ColorConverter.LCH = [66, 80, 48];
    const redBase: ColorConverter.LCH = [58, 73, 29];

    const purpleBg = findSufficientlyContrastyBackground(purpleBase, labelTitle) || purpleBase;
    const blueBg = findSufficientlyContrastyBackground(blueBase, labelTitle) || blueBase;
    const tealBg = findSufficientlyContrastyBackground(tealBase, labelTitle) || tealBase;
    const greenBg = findSufficientlyContrastyBackground(greenBase, labelTitle) || greenBase;
    const yellowBg = findSufficientlyContrastyBackground(yellowBase, labelTitle) || yellowBase;
    const orangeBg = findSufficientlyContrastyBackground(orangeBase, labelTitle) || orangeBase;
    const redBg = findSufficientlyContrastyBackground(redBase, labelTitle) || redBase;

    const lchColors: Record<keyof Color, ColorConverter.LCH> = {
      bgSub,
      bgSubHover,
      bgBase,
      bgBaseHover,
      bgShade,
      bgShadeHover,
      bgSelected,
      bgSelectedHover,
      bgFocus: bg(bgBase, { l: light ? 4 : 8, c: light ? undefined : 2.5 }),

      bgBorder,
      bgBorderHover,
      bgBorderThin,
      bgBorderFaint,
      bgBorderFaintHover,
      bgBorderFaintThin,
      bgBorderSolid,
      bgBorderSolidHover,
      bgBorderSolidThin,
      bgSelectedBorder: border(bgSelected, { l: 3.5, c: 1 }),
      bgSelectedBorderHover: border(bgSelected, { l: 4.5, c: 1 }),

      labelBase,
      labelFaint,
      labelLink,
      labelMuted,
      labelTitle,

      bgModalOverlay: light
        ? [0, 0, 0, 0.1 * shadowContrastBoost]
        : ColorConverter.adjustTo(bgBase, { a: 0.15 * shadowContrastBoost }),

      controlSecondaryHighlight: control(controlSecondary, { l: veryLight ? 15 : light ? -15 : 8, c: 2 }),
      controlLabel: text(input.accent, {}, { c: Math.min(5, input.accent[1]) }),
      controlSelectLabel: labelTitle,
      controlSelectedBg: controlTertiarySelected,

      controlPrimary,
      controlPrimaryHover: bg(controlPrimary, { l: light ? 3 : 5, c: 2 }),
      controlPrimaryLabel: text(input.accent, {}, { c: Math.min(5, input.accent[1]) }),

      controlSecondary,
      controlSecondaryHover: control(controlSecondary, { l: veryLight ? 8 : light ? -15 : 8, c: 2 }),
      controlSecondaryLabel: labelBase,

      controlTertiary,
      controlTertiaryHover: control(
        controlTertiary,
        veryLight ? { l: 10 } : light ? { l: -15 } : veryDark ? { l: 8, a: 0.3 } : { l: 4, a: 0.2 }
      ),
      controlTertiaryLabel: labelBase,
      controlTertiarySelected,

      scrollbarBg: ColorConverter.adjust(labelFaint, { l: light ? 0.3 : 0.4 }),
      scrollbarBgHover: ColorConverter.adjust(labelFaint, { l: 0.8 }),
      scrollbarBgActive: labelFaint,

      blueBase,
      blueBaseHover: bg(blueBase, { l: 5 }),
      blueBg,
      blueMid: bg(blueBase, { l: 8 }),
      blueText: text(blueBase, {}, { l: light ? 50 : 80, c: 80 }),
      blueForeground: ColorConverter.getTextColor(blueBg),

      greenBase,
      greenBaseHover: bg(greenBase, { l: 5 }),
      greenBg,
      greenMid: bg(greenBase, { l: 8 }),
      greenText: text(greenBase, {}, { l: light ? 50 : 80, c: 80 }),
      greenForeground: ColorConverter.getTextColor(greenBg),

      orangeBase,
      orangeBaseHover: bg(orangeBase, { l: 5 }),
      orangeBg,
      orangeMid: bg(orangeBase, { l: 8 }),
      orangeText: text(orangeBase, {}, { l: light ? 50 : 80, c: 80 }),
      orangeForeground: ColorConverter.getTextColor(orangeBg),

      purpleBase,
      purpleBaseHover: bg(purpleBase, { l: 5 }),
      purpleBg,
      purpleMid: bg(purpleBase, { l: 8 }),
      purpleText: text(purpleBase, {}, { l: light ? 50 : 80, c: 80 }),
      purpleForeground: ColorConverter.getTextColor(purpleBg),

      redBase,
      redBaseHover: bg(redBase, { l: 5 }),
      redBg,
      redMid: bg(redBase, { l: 8 }),
      redText: text(redBase, {}, { l: light ? 50 : 80, c: 80 }),
      redForeground: ColorConverter.getTextColor(redBg),

      tealBase,
      tealBaseHover: bg(tealBase, { l: 5 }),
      tealBg,
      tealMid: bg(tealBase, { l: 8 }),
      tealText: text(tealBase, {}, { l: light ? 50 : 80, c: 80 }),
      tealForeground: ColorConverter.getTextColor(tealBg),

      yellowBase,
      yellowBaseHover: bg(yellowBase, { l: 5 }),
      yellowBg,
      yellowMid: bg(yellowBase, { l: 8 }),
      yellowText: text(yellowBase, {}, { l: light ? 50 : 80, c: 80 }),
      yellowForeground: ColorConverter.getTextColor(yellowBg),

      scrollBackground: light ? [100, 0, 0, 0] : [0, 0, 0, 0.004],
      shadowColor: border(light ? [0, 0, 0, 0.03] : [0, 0, 0, 0.15], { l: 1 }),
      textHighlight: light ? [96, 20, 90] : [30, 35, 80],
      focusColor,
      githubLogo: labelMuted,
      sidebarLinkBg: bg(bgBase, { l: light ? 2 : 4, c: light ? undefined : 2 }),
    };

    const themeColor: Color = mapValues(lchColors, c => ColorConverter.toCss(input.colorFormat, c));

    let elevatedTheme: Theme,
      subTheme: Theme,
      sidebarTheme: Theme,
      menuTheme: Theme,
      focusTheme: Theme,
      selectedTheme: Theme;

    const shadow02 = shadow(0.02);
    const shadow04 = shadow(0.04);
    const shadow06 = shadow(0.06);
    const shadow07 = shadow(0.07);
    const shadow08 = shadow(0.08);
    const shadow1 = shadow(0.1);
    const shadow125 = shadow(0.125);
    const shadow2 = shadow(0.2);

    const theme: Theme = {
      focusColor: themeColor.focusColor,
      shadowColor: themeColor.shadowColor,
      contrast: input.contrast,
      colorFormat: input.colorFormat,

      focusShadow: `0 0 0 1px ${themeColor.focusColor}`,
      shadowLow: light
        ? `0px 4px 4px -1px ${shadow02}, 0px 1px 1px 0px ${shadow06}`
        : `0px 4px 4px -1px ${shadow04}, 0px 1px 1px 0px ${shadow08}`,
      shadowBorder: "0 0 0 0.5px " + themeColor.bgBorder,
      shadowMedium: light
        ? `0 3px 8px ${shadow07}, 0 2px 5px ${shadow07}, 0 1px 1px ${shadow07}`
        : `0 3px 8px ${shadow125}, 0 2px 5px ${shadow125}, 0 1px 1px ${shadow125}`,
      shadowHigh: light
        ? `0 4px 30px ${shadow125}, 0 3px 17px ${shadow04}, 0 2px 8px ${shadow04},  0 1px 1px ${shadow04}`
        : `0 4px 40px ${shadow1}, 0 3px 20px ${shadow125},0 3px 12px ${shadow125}, 0 2px 8px ${shadow125}, 0 1px 1px ${shadow125}`,
      shadowPage: light
        ? `0px 4px 4px -1px ${shadow02}, 0px 1px 1px 0px ${shadow06}`
        : `0 3px 8px ${shadow125}, 0 2px 5px ${shadow125}, 0 1px 1px ${shadow125}`,
      shadowInset: light
        ? `0 1px 1px inset ${shadow07}, 0 1px 3px inset ${shadow07}, 0 2px 5px inset ${shadow1}`
        : `0 1px 1px inset ${shadow07}, 0 1px 3px inset ${shadow07}, 0 2px 5px inset ${shadow1}`,
      boxDragShadow: light ? `0px 9px 17px ${shadow125}` : `0px 2px 8px ${shadow2}`,

      inputPadding: "6px 12px",
      inputBackground: themeColor.bgBase,
      inputBorder: `1px solid ${themeColor.bgBorder}`,
      inputBorderRadius: "5px",
      inputFontSize: "0.8125rem",

      color: themeColor,
      isDark: !light,
      highlightVariant: c =>
        ColorConverter.toCss(
          input.colorFormat,
          ColorConverter.adjust(ColorConverter.fromCss(c), veryLight ? { l: -8 } : { l: 8, c: 5 })
        ),
      elevatedTheme: () => {
        // Don't calculate elevated theme for sub theme, just use base theme.
        if (!elevatedTheme) {
          elevatedTheme =
            input.elevation === -1 && input.baseTheme
              ? input.baseTheme
              : generateTheme({
                  ...input,
                  elevation: (input.elevation ?? 0) + 1,
                  baseTheme: theme,
                  _themeType: "elevated",
                  base: bg(lchColors.bgBase, {
                    l: light ? -8 : 3,
                    c: light && !veryLight ? 0 : 1,
                  }),
                });
        }
        return elevatedTheme;
      },
      subTheme: () =>
        // Don't calculate sub theme for elevated theme, just use base theme.
        {
          if (!subTheme) {
            subTheme =
              !veryLight && input.elevation === 1 && input.baseTheme
                ? input.baseTheme
                : {
                    ...generateTheme({
                      ...input,
                      elevation: (input.elevation ?? 0) - 1,
                      base: bgSub,
                      baseTheme: theme,
                      _themeType: "sub",
                    }),
                  };
          }
          return subTheme;
        },
      sidebarTheme: () => {
        if (!sidebarTheme) {
          sidebarTheme = input.sidebarInput
            ? generateTheme({ ...input, ...input.sidebarInput, _themeType: "sidebar" })
            : theme.subTheme();
          const base = input.sidebarInput ? sidebarTheme.elevatedTheme() : theme;
          const sidebarIsLight = input.sidebarInput ? input.sidebarInput.base[0] > 50 : light;
          const sidebarIsVeryLight = input.sidebarInput
            ? sidebarIsLight && input.sidebarInput.base[0] > 98 && input.sidebarInput.base[1] < 8
            : veryLight;
          sidebarTheme.color.controlTertiarySelected = sidebarIsLight ? shadow06 : base.color.bgShade;
          sidebarTheme.color.controlTertiaryHover = sidebarIsLight ? shadow06 : base.color.controlSecondary;

          sidebarTheme.color.controlSecondary = sidebarIsVeryLight
            ? base.color.bgBase
            : sidebarIsLight
            ? base.color.bgShade
            : base.color.controlSecondary;
          sidebarTheme.color.controlSecondaryHover = sidebarIsVeryLight
            ? base.color.bgBaseHover
            : sidebarIsLight
            ? base.color.bgShadeHover
            : base.color.controlSecondaryHover;
        }
        return sidebarTheme;
      },
      menuTheme: () => {
        if (!menuTheme) {
          // If the current theme is not base or elevated, use the base command menu theme.
          const useBaseTheme = theme.baseTheme !== undefined && !["base", "elevated"].includes(input._themeType ?? "");
          menuTheme =
            (useBaseTheme && theme.baseTheme?.menuTheme()) ||
            generateTheme({
              ...input,
              baseTheme: theme,
              _themeType: "menu",
              base: bg(lchColors.bgBase, {
                l: light ? -8 : 5,
                c: light && !veryLight ? 0 : 1,
              }),
            });
        }
        return menuTheme;
      },
      selectedTheme: () => {
        if (!selectedTheme) {
          selectedTheme = generateTheme({
            ...input,
            base: lchColors.bgSelected,
            baseTheme: theme,
            _themeType: "selected",
          });
        }
        return selectedTheme;
      },
      focusTheme: () => {
        if (!focusTheme) {
          focusTheme = generateTheme({
            ...input,
            base: lchColors.bgFocus,
            baseTheme: theme,
            _themeType: "focus",
          });
        }
        return focusTheme;
      },
      baseTheme: input.baseTheme,
    };
    return theme;
  }

  /**
   * Memoized version of above function which returns a theme object based on theme input.
   *
   * This memoizes based on the resolver which created a string from the custom colors + whether or not the theme is for
   * the sidebar.
   *
   * @param input Input to generate the theme from
   * @return Custom theme based on the input provided
   */
  export const generateTheme = memoize(
    ThemeHelper.generateThemeUnmemoized,
    (input: ThemeInput) => Object.values(input).join("_") + Object.values(input.sidebarInput ?? {}).join("_")
  );

  /**
   * Input to generate a theme from.
   */
  export type ThemeInput = {
    contrast: number;
    accent: ColorConverter.LCH;
    base: ColorConverter.LCH;
    colorFormat: ColorConverter.ColorFormat;
    elevation?: number;
    baseTheme?: Theme;
    sidebarInput?: Pick<ThemeInput, "contrast" | "base" | "accent">;
    _themeType?: "base" | "elevated" | "sub" | "sidebar" | "menu" | "selected" | "focus";
  };
}

function mapValues<T extends Object, U>(
  obj: T,
  iteratee: (value: T[keyof T], key: keyof T) => U
): { [K in keyof T]: U } {
  const result = {} as { [K in keyof T]: U };

  Object.keys(obj).forEach(key => {
    const typedKey = key as keyof T;
    result[typedKey] = iteratee(obj[typedKey], typedKey);
  });
  return result;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Memoizes a function based on the resolver provided
 */
export function memoize<T extends (...args: any) => any>(func: T, resolver: (...args: Parameters<T>) => string): T {
  const cache = new Map<string, ReturnType<T>>();
  return ((...args2: Parameters<T>) => {
    const key = resolver(...args2);
    if (cache.has(key)) {
      return cache.get(key)!;
    }
    const result = func(...(args2 as any));
    cache.set(key, result);
    return result;
  }) as T;
}

function findSufficientlyContrastyBackground(
  background: ColorConverter.LCH,
  foreground: ColorConverter.LCH
): ColorConverter.LCH | undefined {
  const [baseL, baseC, baseH] = background;
  const [referenceL] = foreground;

  // Binary search for lightness
  let minL = 0;
  let maxL = 100;
  let bestL = maxL;
  let hasValidColor = false;

  while (maxL - minL > 1) {
    const midL = (minL + maxL) / 2;
    const currentColor: ColorConverter.LCH = [midL, baseC, baseH];

    if (ColorConverter.sufficientContrastForText(foreground, currentColor)) {
      hasValidColor = true;
      bestL = midL;
      if (referenceL > midL) {
        minL = midL;
      } else {
        maxL = midL;
      }
    } else {
      if (referenceL > midL) {
        maxL = midL;
      } else {
        minL = midL;
      }
    }
  }

  if (hasValidColor) {
    return [bestL, baseC, baseH];
  }

  // If not found, try reducing chroma
  const chromaSteps = [baseC * 0.75, baseC * 0.5, baseC * 0.25, 0];
  for (const c of chromaSteps) {
    minL = baseL;
    maxL = 100;
    bestL = maxL;
    hasValidColor = false;

    while (maxL - minL > 0.1) {
      const midL = (minL + maxL) / 2;
      const currentColor: ColorConverter.LCH = [midL, c, baseH];

      if (ColorConverter.sufficientContrastForText(currentColor, foreground)) {
        hasValidColor = true;
        bestL = midL;
        maxL = midL;
      } else {
        minL = midL;
      }
    }

    if (hasValidColor) {
      return [bestL, c, baseH];
    }
  }

  return undefined;
}
