import styled, { css } from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { type FontSize, truncate, fontSize, truncateMultiline, fontWeight } from "../styles/mixins";
import type { Colors } from "../styles/Theme";
import { fontFamily } from "../styles/styled";

/** Props for the Text component. */
export interface TextProps extends React.HTMLAttributes<HTMLSpanElement> {
  /** The type of the text. Defaults to regular. */
  type?: FontSize;
  /** The color of the text. Defaults to labelTitle. */
  color?: Colors | "currentColor";
  /** The alignment of the text. Defaults to left. */
  align?: "left" | "center" | "right";
  /** Whether the text can be selected by the user. Defaults to false. */
  selectable?: boolean;
  /** Whether to truncate the text with ellipsis if it doesn't fit its container. Defaults to false. Pass a number to clamp the text to the given number of lines. */
  truncate?: boolean | number;
  /** Whether the text should be displayed as tabular nums (ensures numbers take up the same horizontal width) */
  tabularNums?: boolean;
  /** Whether the text should not wrap to a new line */
  nowrap?: boolean;
  /** Whether the browser should insert line breaks within an otherwise unbreakable string to prevent text from overflowing its line box */
  overflowWrap?: "normal" | "break-word" | "anywhere";
  /** The font family to use. Defaults to regular if not set. */
  fontFamily?: "display" | "monospace";
  /** Text transform to apply. */
  transform?: "uppercase" | "lowercase" | "capitalize";
  children?: React.ReactNode;
  /** How the text should be wrapped. Defaults to normal, use "pretty" to avoid widows */
  wrap?: "pretty" | "balance" | "normal";
}

/** The different supported text styles. */
export const textSize = fontSize;

/** A text span that sets the font size and color. */
export const Text = styled("span").withConfig({
  shouldForwardProp: prop => !["selectable", "color", "type"].includes(prop) && isPropValid(prop),
})<TextProps>`
  font-style: normal;
  line-height: normal;
  font-weight: normal;
  ${props => (props.align ? `text-align: ${props.align};` : "")}
  ${props =>
    typeof props.truncate === "number" ? truncateMultiline(props.truncate) : props.truncate ? truncate() : ""};
  ${props => (props.nowrap ? "white-space: nowrap;" : "")};
  ${props => (props.overflowWrap ? `overflow-wrap: ${props.overflowWrap};` : "")};
  ${props => (props.transform ? `text-transform: ${props.transform};` : "")};
  ${props => (props.wrap ? `text-wrap: ${props.wrap};` : "")};

  color: ${props => (props.color === "currentColor" ? "currentColor" : props.theme.color[props.color || "labelTitle"])};

  & b {
    font-weight: ${fontWeight("medium")};
    color: ${props => props.theme.color.labelBase};
  }

  ${props => textTypeToCss(props.type ?? "regular")}

  ${props => (props.selectable ? `user-select: text;` : "")};

  ${props => (props.tabularNums ? `font-variant-numeric: tabular-nums;` : "")};

  ${props =>
    props.fontFamily === "display"
      ? `
          font-weight: ${fontWeight("semibold")};
          font-family: ${fontFamily("display")};
          font-feature-settings: "calt";
          font-variation-settings: "opsz" 32;
        `
      : props.fontFamily === "monospace"
      ? `font-family: ${fontFamily("monospace")};`
      : ""};
`;

Text.defaultProps = {
  type: "regular",
  align: "left",
  selectable: false,
  truncate: false,
};

/** Generates CSS string for a given FontSize to handle line-height, font-size, font-weight, and letter-spacing */
export function textTypeToCss(type: FontSize) {
  let lineHeight = "normal";
  let weight = fontWeight("normal");
  let extraFontWeight = "";
  let letterSpacing = "";

  if (type.endsWith("Plus") || type === "title2" || type === "title3") {
    weight = fontWeight("medium");

    if (type === "smallPlus" || type === "regularPlus") {
      extraFontWeight = `& b {
        font-weight: ${fontWeight("bold")};
       }`;
    }
  }

  if (type === "regular" || type === "regularPlus") {
    lineHeight = "1.4375rem";
  } else if (type === "title2") {
    lineHeight = "2rem";
  } else if (type === "title1") {
    lineHeight = "2.875rem";
  }

  if (type === "title2" || type === "title3") {
    letterSpacing = "-0.01rem";
  }

  return css`
    line-height: ${lineHeight};
    font-size: ${fontSize(type)};
    font-weight: ${weight};
    ${extraFontWeight}
    ${letterSpacing ? `letter-spacing: ${letterSpacing};` : ""}
  `;
}
