import { createGlobalStyle, css } from "styled-components";
import { focusOutline } from "@linear/orbiter/styles/mixins";
import { popperStyles } from "@linear/orbiter/components/Popover";
import type { Theme } from "@linear/orbiter/styles/Theme.ts";
import reset from "./reset";
import { fontFamily } from "./styled";

export const BaseStyles = createGlobalStyle`
  ${reset}

  * {
    box-sizing: border-box;
    touch-action: pan-x pan-y;
  }

  :root {
    --font-monospace: "SFMono Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
    --font-regular: "Inter UI", "SF Pro Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    --font-display: "Inter Display", var(--font-regular);
    --font-emoji: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Twemoji Mozilla",
      "Noto Color Emoji", "Android Emoji";

    --speed-highlightFadeIn: 0s;
    --speed-highlightFadeOut: 0.15s;
    --speed-quickTransition: 0.1s;
    --speed-regularTransition: 0.25s;
    --speed-slowTransition: 0.35s;

    --font-size-micro: 0.6875rem;
    --font-size-microPlus: 0.6875rem;
    --font-size-mini: 0.75rem;
    --font-size-miniPlus: 0.75rem;
    --font-size-small: 0.8125rem;
    --font-size-smallPlus: 0.8125rem;
    --font-size-regular: 0.9375rem;
    --font-size-regularPlus: 0.9375rem;
    --font-size-large: 1.125rem;
    --font-size-largePlus: 1.125rem;
    --font-size-title1: 2.25rem;
    --font-size-title2: 1.5rem;
    --font-size-title3: 1.25rem;

    --font-weight-light: 300;
    --font-weight-normal: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;

    --radius-circle: 50%;
    --radius-rounded: 9999px;

    --line-height: 1.625rem;
  }

  html,
  body {
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

  body,
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: ${fontFamily("regular")};
  }

  body {
    line-height: 1.5;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -o-text-size-adjust: none;
    -webkit-text-size-adjust: 100%;
  }

  a {
    text-decoration: none;
  }

  p {
    line-height: var(--line-height);
    font-size: 0.9375rem;
  }

  svg,
  img {
    flex-shrink: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.25;
    margin-top: 0;
    margin-bottom: 0em;
  }

  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.25em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.875em;
  }
  h6 {
    font-size: 0.75em;
  }

  p,
  dl,
  ol,
  ul,
  pre,
  blockquote {
    margin-top: 0.8em;
    margin-bottom: 1.2em;
  }

  i,
  em {
    font-style: italic;
  }

  strong,
  b {
    font-weight: 600;
  }

  code {
    font-family: ${fontFamily("monospace")};
    font-size: 0.95em;
  }

  &:focus-visible {
    ${focusOutline}
  }

  ${popperStyles}
`;

/**
 * Base Linear gradient background.
 */
export const baseBgGradient = (theme: Theme) =>
  theme.isDark
    ? css`
        background: linear-gradient(180deg, ${theme.color.bgBase} 0%, ${theme.color.bgSub} 50%);
      `
    : css`
        background: ${theme.color.bgBase};
      `;
