import * as React from "react";
import { Icon, type IconProps } from "@linear/orbiter/icons/Icon";

type Props = IconProps & {
  /** The completion percentage of the icon, between 0 and 1 */
  percentage: number;
};

/**
 * Create a status icon with percentage and color
 *
 * Math from https://danielpataki.com/svg-pie-chart-javascript/
 */
export const StatusIconDynamic = (props: Props) => {
  const { percentage, color, ...etc } = props;
  const L = 3.5;
  const angle = 360 * props.percentage;
  const angleCalc = angle > 180 ? 360 - angle : angle;
  const angleRad = (angleCalc * Math.PI) / 180;
  const z = Math.sqrt(2 * L * L - 2 * L * L * Math.cos(angleRad));
  const x = angleCalc <= 90 ? L * Math.sin(angleRad) : L * Math.sin(((180 - angleCalc) * Math.PI) / 180);
  const Y = Math.sqrt(z * z - x * x);
  let X;
  let arcSweep: number;
  if (angle <= 180) {
    X = L + x;
    arcSweep = 0;
  } else {
    X = L - x;
    arcSweep = 1;
  }

  return (
    <Icon width="14" height="14" viewBox="0 0 14 14" noFill {...etc}>
      <svg>
        <rect x="1" y="1" width="12" height="12" rx="6" stroke={props.color} strokeWidth="1.5" fill="none" />
        <path
          fill={props.color}
          stroke="none"
          d={`M ${L},${L} L${L},0 A${L},${L} 0 ${arcSweep},1 ${X}, ${Y} z`}
          transform={`translate(${L},${L})`}
        />
      </svg>
    </Icon>
  );
};
