import styled from "styled-components";
import { isPureTouchDevice } from "../utils/browser";
import { avatarSizeToPixelSize, type AvatarSize } from "./Avatar";

/** Styled container for avatars */
export const AvatarContainer = styled.div<{ size: AvatarSize; disabled?: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  line-height: 0;
  flex-shrink: 0;
  aspect-ratio: 1 / 1;
  ${props => (props.disabled ? "opacity: 0.5;" : "")};
  ${props => avatarSizeToCSS(props.size)}
`;

/** Generates width and height CSS based on the avatar size. */
export function avatarSizeToCSS(size: AvatarSize): string {
  const pixelSize = avatarSizeToPixelSize(size);

  let width: number = pixelSize;
  let height: number = pixelSize;

  if (size === "micro") {
    width = isPureTouchDevice ? 17 : pixelSize;
    height = isPureTouchDevice ? 17 : pixelSize;
  } else if (size === "tiny") {
    width = isPureTouchDevice ? 18 : pixelSize;
    height = isPureTouchDevice ? 18 : pixelSize;
  }

  return `
    width: ${width}px;
    height: ${height}px;
  `;
}
