import { useEffect } from "react";
import type { PluginMessage } from "shared/messages";

function handleMessageEvent(callback: (message: PluginMessage) => void) {
  return function onMessageEvent(event: MessageEvent) {
    /**
     * Ensure the post message is from Figma and not another origin (LIN-34223).
     */
    if (!["https://www.figma.com", window.location.origin].includes(event.origin)) {
      throw new Error(`Invalid origin for post message: ${event.origin}`);
    }

    if (event.data.pluginMessage && callback) {
      const message = JSON.parse(event.data.pluginMessage) as PluginMessage;
      callback(message);
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
function noop() {}

/**
 * Adds an event listener for plugin messages.
 *
 * @param onMessageEvent - Callback for when a plugin message is received.
 * @param options - Options for the event listener.
 */
export function useMessageEventListener(
  onMessageEvent?: (message: PluginMessage) => void,
  options?: boolean | AddEventListenerOptions
) {
  useEffect(() => {
    const handleMessage = onMessageEvent ? handleMessageEvent(onMessageEvent) : noop;

    if (onMessageEvent) {
      window.addEventListener("message", handleMessage, options);
    }

    return () => {
      if (onMessageEvent) {
        window.removeEventListener("message", handleMessage, options);
      }
    };
  }, [onMessageEvent]);
}
