import { getColorFormat } from "./getColorFormat";

/**
 * Helpers to convert between lch and rgb colors.
 * Mostly copied from https://www.w3.org/TR/css-color-4/#color-conversion-code
 */
export namespace ColorConverter {
  export const HEX_REGEX_LOOSE = /#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?/i;
  export const HEX_REGEX = new RegExp(`^${HEX_REGEX_LOOSE.source}$`, "i");
  export const HEX_REGEX_SMALL_LOOSE = /#?([a-f\d])([a-f\d])([a-f\d])/i;
  export const HEX_REGEX_SMALL = new RegExp(`^${HEX_REGEX_SMALL_LOOSE.source}$`, "i");
  export const HEX_REGEX_STRICT = /^#([a-f\d]{6}|[a-f\d]{3})$/i;

  export const LCH_REGEX_LOOSE =
    /lch\((\d{1,3}(?:\.\d+)?)\% (\d{1,3}(?:\.\d+)?) (\d{1,3}(?:\.\d+)?)(?: \/ ([1|0](?:\.\d+)?)?)?\)/i;
  export const LCH_REGEX = new RegExp(`^${LCH_REGEX_LOOSE.source}$`, "i");
  export const P3_REGEX_LOOSE = /color\(display-p3 (\d{1,3}(?:\.\d+)?)\ (\d{1,3}(?:\.\d+)?) (\d{1,3}(?:\.\d+)?)\)/i;
  export const P3_REGEX = new RegExp(`^${P3_REGEX_LOOSE.source}$`, "i");
  export const ANY_COLOR_REGEX_LOOSE = new RegExp(
    `(?:${HEX_REGEX_LOOSE.source})|(?:${LCH_REGEX_LOOSE.source})|(?:${P3_REGEX_LOOSE.source})`,
    "i"
  );
  export const ANY_COLOR_REGEX = new RegExp(`^${ANY_COLOR_REGEX_LOOSE.source}$`, "i");

  export type LCH = [lightness: number, chroma: number, hue: number, alpha?: number];
  export interface LCHAdjustments {
    /** The amount of the chroma value of the color to adjust. Ranges from -132 to 132. */
    c?: number;
    /** The amount of the lightness value of the color to adjust. Ranges from -100 to 100. */
    l?: number;
    /** The amount of the hue value of the color to adjust. Ranges from -360 to 360. */
    h?: number;
    /** The amount of the alpha value of the color to adjust. Ranges from -1 to 1. */
    a?: number;
  }

  export type ColorFormat = "LCH" | "P3" | "RGB";
  const D50 = [0.3457 / 0.3585, 1.0, (1.0 - 0.3457 - 0.3585) / 0.3585];

  /**
   * Converts an [l, c, h] color array to a css color string based on the .
   *
   * @param colorFormat The color format that should be returned by the function
   * @param lch An [l, c, h] array
   * @returns The corresponding #RRBBGG, p3 or LCH color string
   */
  export function toCss(colorFormat: ColorFormat, [lightness, chroma, hue, alpha]: LCH) {
    return colorFormat === "LCH"
      ? `lch(${fixed(lightness)}% ${fixed(chroma)} ${fixed(hue)}${alpha !== undefined ? " / " + fixed(alpha) : ""})`
      : colorFormat === "P3"
      ? lchToP3String([lightness, chroma, hue, alpha])
      : lchToRgbString([lightness, chroma, hue, alpha]);
  }

  /**
   * Converts an hex or lch string color to the matching LCH array
   *
   * @param color Any HEX or lch color
   * @returns The corresponding [l, c, h] array
   */
  export function fromCss(color: string): LCH {
    let rgbArr = HEX_REGEX.exec(color);
    if (rgbArr == null) {
      rgbArr = HEX_REGEX_SMALL.exec(color);
      if (rgbArr) {
        rgbArr[1] = rgbArr[1] + rgbArr[1];
        rgbArr[2] = rgbArr[2] + rgbArr[2];
        rgbArr[3] = rgbArr[3] + rgbArr[3];
      }
    }

    if (rgbArr) {
      const solidLch = ColorConverter.rgbToLch([
        parseInt(rgbArr[1], 16),
        parseInt(rgbArr[2], 16),
        parseInt(rgbArr[3], 16),
      ]);
      if (rgbArr[4] && !/ff/i.test(rgbArr[4])) {
        solidLch[3] = parseInt(rgbArr[4], 16) / 255;
      }
      return solidLch;
    } else {
      const lchArr = LCH_REGEX.exec(color);
      if (lchArr) {
        const result: LCH = [parseFloat(lchArr[1]), parseFloat(lchArr[2]), parseFloat(lchArr[3])];
        if (lchArr[4] && lchArr[4] !== "1") {
          result[3] = parseFloat(lchArr[4]);
        }
        return result;
      } else {
        const p3Arr = P3_REGEX.exec(color);
        if (p3Arr) {
          return p3ToLch([parseFloat(p3Arr[1]), parseFloat(p3Arr[2]), parseFloat(p3Arr[3])]);
        }
      }
    }
    return [0, 0, 0];
  }

  /**
   * Adds alpha to a supplied css color.
   *
   * @param color The base color
   * @param alpha The alpha to set (0-1)
   * @param colorFormat Enforces the output color format. Default to best supported format if not specified.
   * @returns a css color string with the applied alpha
   */
  export function cssWithAlpha(color: string, alpha: number, colorFormat?: ColorFormat): string {
    return ColorConverter.toCss(
      colorFormat ?? getColorFormat(),
      ColorConverter.adjustTo(ColorConverter.fromCss(color), { a: alpha })
    );
  }
  /**
   * Returns an accessible text color for a given background color.
   *
   * @param bgColor LCH color of the background
   * @returns an LCH color that is readable on the background
   */
  export function getTextColor(bgColor: LCH): LCH {
    // Trivial implementation. Can be improved & extended with a11y options etc.
    const [l, c, h] = bgColor;
    return [l - c * 0.075 > 65 ? 0 : 100, Math.min(c / 2, c), h];
  }

  /**
   * Calculates the deltaE between two colors.
   * See http://www.brucelindbloom.com/index.html?Eqn_DeltaE_CMC.html
   *
   * @param color1 LCH color
   * @param color2 LCH color
   * @returns the perceptual delta between the two colors
   */
  export function deltaE(color1: LCH, color2: LCH): number {
    // Implementation mostly copied from chroma.
    // See http://www.brucelindbloom.com/index.html?Eqn_DeltaE_CMC.html
    const L = 1; // Weight for lightness
    const C = 3; // Weight for chroma
    const [L1, a1, b1] = LCH_to_Lab(color1);
    const [L2, a2, b2] = LCH_to_Lab(color2);
    const c1 = Math.sqrt(a1 * a1 + b1 * b1);
    const c2 = Math.sqrt(a2 * a2 + b2 * b2);
    const sl = L1 < 16.0 ? 0.511 : (0.040975 * L1) / (1.0 + 0.01765 * L1);
    const sc = (0.0638 * c1) / (1.0 + 0.0131 * c1) + 0.638;
    let h1 = c1 < 0.000001 ? 0.0 : (Math.atan2(b1, a1) * 180.0) / Math.PI;
    while (h1 < 0) {
      h1 += 360;
    }
    while (h1 >= 360) {
      h1 -= 360;
    }
    const t =
      h1 >= 164.0 && h1 <= 345.0
        ? 0.56 + Math.abs(0.2 * Math.cos((Math.PI * (h1 + 168.0)) / 180.0))
        : 0.36 + Math.abs(0.4 * Math.cos((Math.PI * (h1 + 35.0)) / 180.0));
    const c4 = c1 * c1 * c1 * c1;
    const f = Math.sqrt(c4 / (c4 + 1900.0));
    const sh = sc * (f * t + 1.0 - f);
    const delL = L1 - L2;
    const delC = c1 - c2;
    const delA = a1 - a2;
    const delB = b1 - b2;
    const dH2 = delA * delA + delB * delB - delC * delC;
    const v1 = delL / (L * sl);
    const v2 = delC / (C * sc);
    const v3 = sh;
    return Math.sqrt(v1 * v1 + v2 * v2 + dH2 / (v3 * v3));
  }

  /**
   * Calculates the  APCA contrast between two colors.
   * See https://github.com/Myndex/apca-w3
   *
   * @param foreground LCH color
   * @param background LCH color
   * @returns the contrast value, where > 30 is the lowest sufficient value for text
   */
  export function apcaContrast(foreground: LCH, background: LCH): number {
    // Adapted from https://github.com/LeaVerou/color.js/blob/main/src/contrast/APCA.js
    const normBG = 0.56;
    const normTXT = 0.57;
    const revTXT = 0.62;
    const revBG = 0.65;

    const loClip = 0.1;
    const deltaYmin = 0.0005;

    const scaleBoW = 1.14;
    const loBoWoffset = 0.027;
    const scaleWoB = 1.14;
    const lumTxt = foreground[0] / 100;

    const lumBg = background[0] / 100;
    let S;
    let C;
    let Sapc;
    // toe clamping of very dark values to account for flare
    const Ytxt = fclamp(lumTxt);
    const Ybg = fclamp(lumBg);

    // are we "Black on White" (dark on light), or light on dark?
    const BoW = Ybg > Ytxt;

    // why is this a delta, when Y is not perceptually uniform?
    // Answer: it is a noise gate, see
    // https://github.com/LeaVerou/color.js/issues/208
    if (Math.abs(Ybg - Ytxt) < deltaYmin) {
      C = 0;
    } else {
      if (BoW) {
        // dark text on light background
        S = Ybg ** normBG - Ytxt ** normTXT;
        C = S * scaleBoW;
      } else {
        // light text on dark background
        S = Ybg ** revBG - Ytxt ** revTXT;
        C = S * scaleWoB;
      }
    }
    if (Math.abs(C) < loClip) {
      Sapc = 0;
    } else if (C > 0) {
      // not clear whether Woffset is loBoWoffset or loWoBoffset
      // but they have the same value
      Sapc = C - loBoWoffset;
    } else {
      Sapc = C + loBoWoffset;
    }

    return Math.abs(Sapc * 100);
  }

  /**
   * Using APCA, checks if two colors have sufficient contrast for text.
   *
   * @param foreground
   * @param background
   * @param threshold The threshold for sufficient contrast. Default is 38.
   * @returns true if the contrast between the two colors is enough for use with text
   */
  export function sufficientContrastForText(foreground: LCH, background: LCH, threshold: number = 38): boolean {
    // The default is roughly equivalent to WCAG AA for large text, but the threshold we should use really depends on text size, weight etc.
    return apcaContrast(foreground, background) > threshold;
  }

  /**
   * Checks if a color is bright
   *
   * @param bgColor LCH color
   * @returns true if the color is bright, false
   */
  export function isBright(color: LCH): boolean {
    // Trivial implementation. Can be improved & extended with a11y options etc.
    return color[0] > 50;
  }

  /**
   * Adjusts a color based on input
   *
   * @param color LCH color
   * @param adjustments record of adjustments to make to [l,c,h,a]
   * @returns an LCH color
   */
  export function adjust(color: LCH, adjustments: LCHAdjustments): LCH {
    const [l, c, h, a = 1] = color;
    return [
      clamp(l + (adjustments.l ?? 0), 0, 100),
      clamp(c + (adjustments.c ?? 0), 0, 132),
      clamp(h + (adjustments.h ?? 0), 0, 360),
      clamp(a + (adjustments.a ?? 0), 0, 1),
    ];
  }

  /**
   * Adjusts a color based on input
   *
   * @param color LCH color
   * @param adjustments record of adjustments to make to [l,c,h,a]
   * @returns an LCH color
   */
  export function adjustTo(color: LCH, adjustments: LCHAdjustments): LCH {
    const [l, c, h, a = 1] = color;
    return [
      clamp(adjustments.l ?? l, 0, 100),
      clamp(adjustments.c ?? c, 0, 132),
      clamp(adjustments.h ?? h, 0, 360),
      clamp(adjustments.a ?? a, 0, 1),
    ];
  }

  /**
   * Mixes two colors
   *
   * @param color1 LCH color
   * @param color2 LCH color
   * @param factor how much the colors should mix. 0 = color1, 1 = color2
   * @returns an LCH color
   */
  export function mix(color1: LCH, color2: LCH, factor: number): LCH {
    const a1 = color1[3] ?? 1;
    const a2 = color2[3] ?? 1;
    const [x1, y1, z1] = Lab_to_XYZ(LCH_to_Lab(color1));
    const [x2, y2, z2] = Lab_to_XYZ(LCH_to_Lab(color2));
    const xyz: NumberTriple = [
      x1 * (1 - factor) + factor * x2,
      y1 * (1 - factor) + factor * y2,
      z1 * (1 - factor) + factor * z2,
    ];
    const [l, c, h] = Lab_to_LCH(XYZ_to_Lab(xyz));
    return [clamp(l, 0, 100), clamp(c, 0, 132), clamp(h, 0, 360), (a1 + a2) / 2];
  }

  /**
   * Mixes two css colors
   *
   * @param color1 css color
   * @param color2 css color
   * @param factor how much the colors should mix. 0 = color1, 1 = color2
   * @returns an css (RGB) color
   */
  export function mixCss(color1: string, color2: string, factor: number): string {
    return ColorConverter.toCss(
      getColorFormat(),
      ColorConverter.mix(ColorConverter.fromCss(color1), ColorConverter.fromCss(color2), factor)
    );
  }

  /**
   * Converts an [l, c, h] color array to an #RRBBGG color string.
   *
   * @param lch An [l, c, h] array
   * @returns The corresponding #RRBBGG color string
   */
  export function lchToRgbString(lch: LCH): string {
    const rgb = lchToRgb(lch)
      .map(n => n.toString(16).split(".")[0])
      .map(s => (s.length === 1 ? "0" + s : s));
    const alpha = lch[3] !== undefined && lch[3] !== 1 ? padWithZero((lch[3] * 255).toString(16).split(".")[0]) : "";

    return `#${rgb[0]}${rgb[1]}${rgb[2]}${alpha}`;
  }

  function padWithZero(s: string) {
    return s.length === 1 ? "0" + s : s;
  }

  /**
   * Checks if a supplied string is in a valid color format (#rrggbb, p3 or LCH).
   *
   * @param color a string representing a color
   * @param options used to specify the format of the color, and the level of strictness
   * @returns true if the color is valid, false otherwise.
   */
  export function isValidColor(
    color: string,
    options:
      | {
          format?: "p3" | "lch";
        }
      | {
          format?: "hex";
          level?: "loose" | "strict" | "small";
        }
  ) {
    switch (options.format) {
      case "hex":
        switch (options.level) {
          case "loose":
            return HEX_REGEX_LOOSE.test(color);
          case "small":
            return HEX_REGEX_SMALL.test(color);
          default:
            return HEX_REGEX_STRICT.test(color);
        }
      case "p3":
        return P3_REGEX.test(color);
      case "lch":
        return LCH_REGEX.test(color);
      default:
        return ANY_COLOR_REGEX.test(color);
    }
  }

  /**
   * Converts any valid css color string to #rrggbb.
   *
   * @param color : a valid css color string (#rrggbb, p3 or LCH);
   * @returns The corresponding #rrggbb string
   */
  export function cssToRgb(color: string): string {
    return HEX_REGEX.test(color) ? color : lchToRgbString(fromCss(color));
  }

  /**
   * Converts an [l, c, h] color array to an dislay P3 color string.
   *
   * @param lch An [l, c, h] array
   * @returns The corresponting #RRBBGG color string
   */
  function lchToP3String(lch: LCH): string {
    const rgb = lchToP3(lch);
    return `color(display-p3 ${rgb[0]} ${rgb[1]} ${rgb[2]}${lch[3] !== undefined ? ` / ${lch[3].toString(10)}` : ""})`;
  }

  function lchToP3(lch: LCH): NumberTriple {
    return XYZ_to_P3(D50_to_D65(Lab_to_XYZ(LCH_to_Lab(lch))))
      .map(rgbGamma)
      .map(a => clamp(a, 0, 1)) as NumberTriple;
  }

  /**
   * Convert a hex color string to an lch color.
   *
   * @param rgb An array of [r, g, b, a?] values.
   * @returns An [l, c, h] array.
   */
  export function rgbToLch(rgb: [r: number, g: number, b: number]): LCH {
    // Convert from gamma-encoded RGB to linear-light RGB (undo gamma encoding)
    // Convert from linear RGB to CIE XYZ
    // If needed, convert from a D65 whitepoint (used by sRGB, display-p3, a98-rgb and rec2020) to the D50 whitepoint used in Lab, with the Bradford transform. prophoto-rgb already has a D50 whitepoint.
    // Convert D50-adapted XYZ to Lab
    return Lab_to_LCH(XYZ_to_Lab(D65_to_D50(lin_sRGB_to_XYZ(srgbLinear(rgb)))));
  }

  function p3ToLch(p3Color: [number, number, number]) {
    return Lab_to_LCH(XYZ_to_Lab(D65_to_D50(lin_P3_to_XYZ(srgbLinear(p3Color)))));
  }

  function lchToRgb(lch: LCH): NumberTriple {
    return labToRGB(LCH_to_Lab(lch));
  }

  function multiplyMatrix(
    [[x1, x2, x3], [y1, y2, y3], [z1, z2, z3]]: number[][],
    [a, b, c]: NumberTriple
  ): NumberTriple {
    return [x1 * a + x2 * b + x3 * c, y1 * a + y2 * b + y3 * c, z1 * a + z2 * b + z3 * c];
  }

  // standard white points, defined by 4-figure CIE x,y chromaticities

  const labToRGB = (lab: NumberTriple): NumberTriple => {
    // If the color is pure white, avoid the conversion and return white.
    // Conversion has rounding errors, resulting in not pure white result.
    if (lab[0] === 100 && lab[1] === 0 && lab[2] === 0) {
      return [255, 255, 255];
    }
    const xyz = Lab_to_XYZ(lab);

    const d65 = D50_to_D65(xyz);
    return srgbGamma(XYZ_to_lin_sRGB(d65)).map(n => clamp(n, 0, 255)) as NumberTriple;
  };

  function rgbGamma(val: number) {
    const sign = val < 0 ? -1 : 1;
    const abs = Math.abs(val);

    if (abs > 0.0031308) {
      return sign * (1.055 * Math.pow(abs, 1 / 2.4) - 0.055);
    }
    return 12.92 * val;
  }

  function srgbGamma(rgb: NumberTriple): NumberTriple {
    return rgb.map(r => 255 * rgbGamma(r)) as NumberTriple;
  }

  function lin_sRGB_to_XYZ(rgb: NumberTriple) {
    // convert an array of linear-light sRGB values to CIE XYZ
    // using sRGB's own white, D65 (no chromatic adaptation)

    const M = [
      [0.41239079926595934, 0.357584339383878, 0.1804807884018343],
      [0.21263900587151027, 0.715168678767756, 0.07219231536073371],
      [0.01933081871559182, 0.11919477979462598, 0.9505321522496607],
    ];
    return multiplyMatrix(M, rgb);
  }

  function lin_P3_to_XYZ(rgb: [number, number, number]) {
    const M = [
      [0.4865709486482162, 0.26566769316909306, 0.1982172852343625],
      [0.2289745640697488, 0.6917385218365064, 0.079286914093745],
      [0.0, 0.04511338185890264, 1.043944368900976],
    ];
    return multiplyMatrix(M, rgb);
  }

  function XYZ_to_lin_sRGB(XYZ: NumberTriple) {
    const M = [
      [3.2409699419045226, -1.537383177570094, -0.4986107602930034],
      [-0.9692436362808796, 1.8759675015077202, 0.04155505740717559],
      [0.05563007969699366, -0.20397695888897652, 1.0569715142428786],
    ];

    return multiplyMatrix(M, XYZ);
  }

  function XYZ_to_P3(XYZ: NumberTriple) {
    const M = [
      [2.493496911941425, -0.9313836179191239, -0.40271078445071684],
      [-0.8294889695615747, 1.7626640603183463, 0.023624685841943577],
      [0.03584583024378447, -0.07617238926804182, 0.9568845240076872],
    ];

    return multiplyMatrix(M, XYZ);
  }

  function D50_to_D65(XYZ: NumberTriple) {
    const M = [
      [0.9554734527042182, -0.023098536874261423, 0.0632593086610217],
      [-0.028369706963208136, 1.0099954580058226, 0.021041398966943008],
      [0.012314001688319899, -0.020507696433477912, 1.3303659366080753],
    ];

    return multiplyMatrix(M, XYZ);
  }

  function D65_to_D50(XYZ: NumberTriple) {
    const M = [
      [1.0479298208405488, 0.022946793341019088, -0.05019222954313557],
      [0.029627815688159344, 0.990434484573249, -0.01707382502938514],
      [-0.009243058152591178, 0.015055144896577895, 0.7518742899580008],
    ];
    return multiplyMatrix(M, XYZ);
  }

  // CIE Lab and LCH

  function XYZ_to_Lab(XYZ: NumberTriple): NumberTriple {
    // Assuming XYZ is relative to D50, convert to CIE Lab
    // from CIE standard, which now defines these as a rational fraction
    const ε = 216 / 24389; // 6^3/29^3
    const κ = 24389 / 27; // 29^3/3^3

    // compute xyz, which is XYZ scaled relative to reference white
    const xyz = XYZ.map((value, i) => value / D50[i]);

    // now compute f
    const f = xyz.map(value => (value > ε ? Math.cbrt(value) : (κ * value + 16) / 116));

    return [
      116 * f[1] - 16, // L
      500 * (f[0] - f[1]), // a
      200 * (f[1] - f[2]), // b
    ];
    // L in range [0,100]. For use in CSS, add a percent
  }

  function Lab_to_XYZ(Lab: NumberTriple): NumberTriple {
    // Convert Lab to D50-adapted XYZ
    // http://www.brucelindbloom.com/index.html?Eqn_RGB_XYZ_Matrix.html
    const κ = 24389 / 27; // 29^3/3^3
    const ε = 216 / 24389; // 6^3/29^3
    const f = [];

    // compute f, starting with the luminance-related term
    f[1] = (Lab[0] + 16) / 116;
    f[0] = Lab[1] / 500 + f[1];
    f[2] = f[1] - Lab[2] / 200;

    // compute xyz
    const xyz = [
      Math.pow(f[0], 3) > ε ? Math.pow(f[0], 3) : (116 * f[0] - 16) / κ,
      Lab[0] > κ * ε ? Math.pow((Lab[0] + 16) / 116, 3) : Lab[0] / κ,
      Math.pow(f[2], 3) > ε ? Math.pow(f[2], 3) : (116 * f[2] - 16) / κ,
    ];

    // Compute XYZ by scaling xyz by reference white
    return xyz.map((value, i) => value * D50[i]) as NumberTriple;
  }

  function Lab_to_LCH(Lab: NumberTriple): LCH {
    // Convert to polar form
    const hue = (Math.atan2(Lab[2], Lab[1]) * 180) / Math.PI;
    return [
      Lab[0], // L is still L
      Math.sqrt(Math.pow(Lab[1], 2) + Math.pow(Lab[2], 2)), // Chroma
      hue >= 0 ? hue : hue + 360, // Hue, in degrees [0 to 360)
      1,
    ];
  }

  function LCH_to_Lab(lch: LCH): NumberTriple {
    // Convert from polar form
    return [
      lch[0], // L is still L
      lch[1] * Math.cos((lch[2] * Math.PI) / 180), // a
      lch[1] * Math.sin((lch[2] * Math.PI) / 180), // b
    ];
  }

  function rgbLinear(val: number) {
    const sign = val < 0 ? -1 : 1;
    const abs = Math.abs(val);

    if (abs < 0.04045) {
      return val / 12.92;
    }

    return sign * Math.pow((abs + 0.055) / 1.055, 2.4);
  }

  function srgbLinear(rgb: NumberTriple): NumberTriple {
    return rgb.map(c => rgbLinear(c / 255)) as NumberTriple;
  }

  type NumberTriple = [number, number, number];
}

function fixed(n: number) {
  return parseFloat(n.toFixed(3));
}

// Used for APCA contrast calculation
const blkThrs = 0.022;
const blkClmp = 1.414;
function fclamp(Y: number) {
  if (Y >= blkThrs) {
    return Y;
  }
  return Y + (blkThrs - Y) ** blkClmp;
}

function clamp(number: number, min: number, max: number): number {
  return Math.max(min, Math.min(max, number));
}
