import * as React from "react";
type Property =
  | "offsetHeight"
  | "scrollHeight"
  | "clientHeight"
  | "offsetWidth"
  | "clientWidth"
  | "scrollWidth"
  | "offsetLeft"
  | "clientRectWidth"
  | "clientRectHeight"
  | "clientRectRight"
  | "clientRectLeft";

/**
 * A hook that returns the requested size property of the component associated with the reference.
 *
 * @param ref React reference for the element to measure.
 * @param property Which element property to return. Can be a single property or an array of two properties. All properties return rounded pixel values, except for
 * clientRectWidth an clientRectHeight, which calculate the width and height using `Element.getBoundingClientRect()`.
 * You should use clientRectWidth and clientRectHeight for any animation that you don't want to pixel-shift when
 * changing the animated width or height to "auto".
 * @returns The height/width of the measured component or undefined, if the property could not be determined (for example during the first render).
 * If property is an array, returns an object with the values for both properties.
 */
export function useComponentSize<T extends HTMLElement, P extends Property | [Property, Property]>(
  ref: React.MutableRefObject<T | null> | null,
  property: P,
  deps?: React.DependencyList
): P extends [Property, Property] ? { [K in P[number]]: number | undefined } : number | undefined {
  type ArrayReturnType = P extends [Property, Property] ? { [K in P[number]]: number | undefined } : never;
  type ReturnType = P extends [Property, Property] ? ArrayReturnType : number | undefined;

  const [size, setSize] = React.useState<ReturnType>(() => {
    if (!ref?.current) {
      return (
        Array.isArray(property) ? { [property[0]]: undefined, [property[1]]: undefined } : undefined
      ) as ReturnType;
    }

    if (Array.isArray(property)) {
      return {
        [property[0]]: getComponentSize(ref.current, property[0]),
        [property[1]]: getComponentSize(ref.current, property[1]),
      } as ReturnType;
    }

    return getComponentSize(ref.current, property) as ReturnType;
  });

  React.useLayoutEffect(() => {
    const current = ref?.current;
    if (!current) {
      setSize(
        (Array.isArray(property) ? { [property[0]]: undefined, [property[1]]: undefined } : undefined) as ReturnType
      );
      return;
    }

    const handleResize = (element?: T) => {
      const target = element || current;
      if (Array.isArray(property)) {
        setSize({
          [property[0]]: getComponentSize(target, property[0]),
          [property[1]]: getComponentSize(target, property[1]),
        } as ReturnType);
      } else {
        setSize(getComponentSize(target, property) as ReturnType);
      }
    };

    handleResize();

    return observeComponentResize<T>(ref, handleResize);
  }, [
    ref,
    ref?.current,
    // Make sure to re-calculate the size if properties change and that the number of dependencies stays the same
    ...(Array.isArray(property) ? [property[0], property[1]] : [property, undefined]),
    // Make sure to re-calculate the size if deps that passed to the hook change
    ...(deps || []),
  ]);

  return size;
}

/**
 * A hook that returns the `clientHeight` of the component associated with the reference.
 *
 * @param ref React reference for the element to measure.
 * @returns The height of the measured component or undefined, if the height could not be determined (for example during the first render).
 */
export function useComponentHeight<T extends HTMLElement>(ref: React.MutableRefObject<T | null>) {
  return useComponentSize<T, "clientHeight">(ref, "clientHeight");
}

/**
 * A hook that returns the `clientWidth` of the component associated with the reference.
 *
 * @param ref React reference for the element to measure.
 * @returns The width of the measured component or undefined, if the width could not be determined (for example during the first render).
 */
export function useComponentWidth<T extends HTMLElement>(ref: React.MutableRefObject<T | null> | null) {
  return useComponentSize<T, "clientWidth">(ref, "clientWidth");
}

/**
 * A hook that returns the `clientWidth` and `clientHeight` of the component associated with the reference.
 *
 * @param ref React reference for the element to measure.
 * @returns The width and height of the measured component or undefined, if the width or height could not be determined (for example during the first render).
 */
export function useComponentWidthAndHeight<T extends HTMLElement>(ref: React.MutableRefObject<T | null> | null) {
  const { clientWidth, clientHeight } = useComponentSize<T, ["clientWidth", "clientHeight"]>(ref, [
    "clientWidth",
    "clientHeight",
  ]);
  return { width: clientWidth, height: clientHeight };
}

/**
 * Returns whether the element the ref points to has overflow or not by comparing client(Width/Height) to scroll(Width/Height).
 *
 * @param ref React ref for the element to measure
 * @returns if the element has overflowing content or not
 */
export function useHasOverflow<T extends HTMLElement>(ref: React.MutableRefObject<T | null> | null) {
  const [hasOverflow, setHasOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    const current = ref?.current;
    if (!current) {
      setHasOverflow(false);
      return;
    }

    // Take the first measurement in the useLayoutEffect so we have it before first paint
    setHasOverflow(elementHasOverflow(current));

    const handleResize = (element?: T) => {
      if (element) {
        setHasOverflow(elementHasOverflow(element));
      }
    };

    handleResize();
    return observeComponentResize(ref, handleResize);
  }, [ref?.current]);

  return hasOverflow;
}

const elementHasOverflow = (el: HTMLElement) => {
  return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
};

/**
 * Extract a specific size from a given HTML element
 *
 * @param element The HTML element to measure
 * @param property The name of the size to measure
 * @returns The size of the element for the given property.
 */
export function getComponentSize<T extends HTMLElement>(element: T | null, property: Property) {
  if (!element) {
    return undefined;
  }
  let value = 0;
  if (property === "scrollHeight") {
    const currentHeight = element.style.height;
    element.style.height = "0px";
    value = element.scrollHeight;
    element.style.height = currentHeight;
  } else if (property === "scrollWidth") {
    const currentWidth = element.style.width;
    element.style.width = "0px";
    value = element.scrollWidth;
    element.style.width = currentWidth;
  } else if (
    property === "clientRectWidth" ||
    property === "clientRectHeight" ||
    property === "clientRectRight" ||
    property === "clientRectLeft"
  ) {
    const rect = element.getBoundingClientRect();
    if (property === "clientRectWidth") {
      return rect.width;
    }
    if (property === "clientRectHeight") {
      return rect.height;
    }
    if (property === "clientRectRight") {
      return rect.right;
    }
    if (property === "clientRectLeft") {
      return rect.left;
    }
  } else {
    value = element[property];
  }
  return value;
}

/** Observe size changes in an HTML element */
export function observeComponentResize<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null> | null,
  handleResize: (element?: T) => void
) {
  const current = ref?.current;

  if (ref == null || current == null) {
    return;
  }

  if (typeof ResizeObserver === "function") {
    let observer: ResizeObserver | null = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (current === ref?.current) {
          handleResize(current);
        }
      });
    });
    observer.observe(current);

    return () => {
      observer?.disconnect();
      observer = null;
    };
  } else {
    const listener = () => handleResize();
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }
}
