import * as React from "react";
import { runInAction } from "mobx";
import { isTextualInput } from "../utils/isTextualInput";

/** Context for KeyboardEventListeners */
export const KeyboardEventListenersContext = React.createContext<KeyboardEventListeners | null>(null);

/**
 * Utility to handle sets of event listeners for keyboard events.
 */
export class KeyboardEventListeners {
  /**
   * Adds one or more listeners.
   *
   * @param eventType the type of event to listen to
   * @param listeners the listeners
   * @returns a function that removes listeners when called
   */
  public addListeners(eventType: "keydown" | "keyup", ...listeners: KeyboardListener[]) {
    for (const listener of listeners) {
      this.listenersForEventType(eventType).unshift(listener);
    }

    return () => {
      for (const listener of listeners) {
        const index = this.listenersForEventType(eventType).indexOf(listener);
        this.listenersForEventType(eventType).splice(index, 1);
      }
    };
  }

  /**
   * Runs all listeners interested in the event. Runs in "reverse order", so the most recently registered listener runs first.
   *
   * @param event the event to handle
   */
  public handleEvent = (event: KeyboardEvent | React.KeyboardEvent) => {
    const activeListeners = this.listenersForEventType(event.type);
    const inputElementHasFocus = event.target && isTextualInput(event.target);

    runInAction(() => {
      for (const listener of activeListeners) {
        if (event.defaultPrevented === true) {
          break;
        }

        if (event.target instanceof HTMLElement && event.target.hasAttribute("data-prevent-global-listener")) {
          break;
        }

        if (!inputElementHasFocus || listener.runWithInputElementFocus) {
          listener.callback(event);
        }
      }
    });
  };

  /**
   * Attaches listeners globally. Should only be used at the root of the app.
   */
  public attachGlobalEventListeners() {
    self.document.body.addEventListener("keydown", this.handleEvent);
    self.document.body.addEventListener("keyup", this.handleEvent);
  }

  /**
   * Removes listeners globally. Should only be used at the root of the app.
   */
  public removeGlobalEventListeners() {
    self.document.body.removeEventListener("keydown", this.handleEvent);
    self.document.body.removeEventListener("keyup", this.handleEvent);
  }

  private listenersForEventType(eventType: string) {
    if (eventType === "keydown") {
      return this.keydownListeners;
    } else if (eventType === "keyup") {
      return this.keyupListeners;
    } else {
      return [];
    }
  }

  private keydownListeners: KeyboardListener[] = [];
  private keyupListeners: KeyboardListener[] = [];
}

/**
 * Keyboard listener defines a callback that will run on events.
 */
type KeyboardListener = {
  runWithInputElementFocus: boolean;
  callback(event: KeyboardEvent | React.KeyboardEvent): void;
};
