/* eslint-disable node/no-process-env */

type ENV = {
  CLIENT_URL?: string;
  API_SERVER_URL?: string;
  FRONT_INTERNAL_OAUTH_CLIENT_ID?: string;
  ZENDESK_INTERNAL_OAUTH_CLIENT_ID?: string;
  FIGMA_INTERNAL_OAUTH_CLIENT_ID?: string;
};

declare const window: { CLIENT_ENV?: ENV } | undefined;

const isBrowser = typeof window !== "undefined";

const getEnv = (key: keyof ENV): string => {
  if (isBrowser) {
    return window.CLIENT_ENV?.[key] ?? "";
  }

  return process.env[key] ?? "";
};

/**
 * Configuration for common.
 */
export const Config = {
  /** Same config is hardcoded in common/src/security/Headers! */
  DESKTOP_PORTS: [44450, 18450, 33234],
  /** The id of the Linear Onboarding team that contains the default issues to copy over to a new team. */
  DEFAULT_ISSUES_TEAM_ID: "fb64079d-35f3-4506-a1de-55d4b86b3e55",
  /** Whether the client is compiled against production. */
  PRODUCTION_ENV: process.env.NODE_ENV === "production",
  /** The URL for the application */
  CLIENT_URL: (getEnv("CLIENT_URL") || process.env.VITE_CLIENT_URL) ?? "https://linear.app",
  /** The URL of the API server */
  API_SERVER_URL: getEnv("API_SERVER_URL"),
  /** Front internal OAuth client ID. */
  FRONT_INTERNAL_OAUTH_CLIENT_ID: getEnv("FRONT_INTERNAL_OAUTH_CLIENT_ID"),
  /** Zendesk internal OAuth client ID. */
  ZENDESK_INTERNAL_OAUTH_CLIENT_ID: getEnv("ZENDESK_INTERNAL_OAUTH_CLIENT_ID"),
  /** Figma internal OAuth client ID. */
  FIGMA_INTERNAL_OAUTH_CLIENT_ID: getEnv("FIGMA_INTERNAL_OAUTH_CLIENT_ID"),
} as const;
