import React from "react";
import styled from "styled-components";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { FormButton } from "~/components/FormButton";
import { handleExternalClick } from "~/utils/links";

/**
 * Styled anchor tag that allows links to be clicked in the error message.
 *
 * @param props - Anchor tag props.
 */
function ErrorLink(props: React.AnchorHTMLAttributes<HTMLAnchorElement>): JSX.Element {
  return <StyledErrorLink {...props} onClick={handleExternalClick} />;
}

interface ErrorFallbackProps {
  /** The error that was thrown. */
  error: Error;
  /** A callback to reset the error boundary. */
  resetError: () => void;
}

/**
 * A component that displays an error and allows the user to reset the
 * application.
 *
 * @param props Error boundary fallback component props.
 */
export function ErrorFallback(props: ErrorFallbackProps): JSX.Element {
  const { resetError } = props;

  return (
    <ErrorFallbackFlex>
      <Flex column gap={gridSpace(2)}>
        <Text align="center" variant="regularPlus">
          Something went wrong
        </Text>
        <Text align="center" color="labelMuted">
          Reload the plugin to try again. If the issue continues to persist, please reach out to our support team using{" "}
          <ErrorLink href="mailto:support@linear.app?subject=Error in Figma plugin">email</ErrorLink> or{" "}
          <ErrorLink href="https://linear.app/join-slack">Slack</ErrorLink>
        </Text>
      </Flex>
      <ErrorResetButton onClick={resetError}>Reload plugin</ErrorResetButton>
    </ErrorFallbackFlex>
  );
}

const ErrorFallbackFlex = styled(Flex)`
  flex-direction: column;
  gap: ${gridSpacePx(6)};
  width: 100%;
`;

const ErrorResetButton = styled(FormButton)`
  width: 100%;
`;

const StyledErrorLink = styled.a`
  color: ${props => props.theme.color.labelMuted};
  text-decoration: underline;

  &:visited {
    color: ${props => props.theme.color.labelMuted};
  }
`;
