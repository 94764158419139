import * as React from "react";
import { Portal } from "react-portal";
import { useTheme } from "styled-components";
import { observer } from "mobx-react-lite";
import type { ObservableRef } from "@linear/orbiter/hooks/useObservableRef";

/** A React context that provides a dom node for portals to be rendered into */
export const PortalContext = React.createContext<ObservableRef<HTMLElement> | null>(null);

/**
 * A portal component (just like react-portal) that uses context to render into the correct dom node.
 */
export const ContextualPortal = observer(function ContextualPortal_(props: { children: React.ReactNode }) {
  const contextRef = React.useContext(PortalContext);
  return <Portal node={contextRef?.current}>{props.children}</Portal>;
});

/**
 * A portal component (just like react-portal) that uses context to render into the correct dom node, and applies theme variables to the node.
 * Not used yet, needs more testing.
 */
export function ThemedContextualPortal(props: { node?: HTMLElement | null; children: React.ReactNode }) {
  const theme = useTheme();
  React.useLayoutEffect(() => {
    if (props.node) {
      for (const [key, value] of Object.entries(theme.color)) {
        props.node.style.setProperty("--color-" + key, value);
      }
      props.node.style.setProperty("--shadow-low", theme.shadowLow);
      props.node.style.setProperty("--shadow-medium", theme.shadowMedium);
      props.node.style.setProperty("--shadow-high", theme.shadowHigh);
      props.node.style.setProperty("--shadow-page", theme.shadowPage);
      props.node.style.setProperty("--shadow-inset", theme.shadowInset);
    }
  }, []);

  return <Portal node={props.node}>{props.children}</Portal>;
}
