import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import * as Popover from "@radix-ui/react-popover";
import { type GetInputPropsOptions, useCombobox } from "downshift";
import { AnimatePresence, motion } from "framer-motion";
import { Text } from "@linear/orbiter/components/Text";
import { fontSize } from "@linear/orbiter/styles/mixins";
import { color } from "@linear/orbiter";
import { LoadingIcon } from "@linear/orbiter/icons/base/LoadingIcon";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { useDelayedLoadingIndicator } from "~/hooks/useDelayedLoadingIndicator";
import { useDelayedAutofocus } from "~/hooks/useDelayedAutofocus";
import { panelXPadding } from "~/styles/panelXPadding";
import { PopoverMenu } from "./Popover/PopoverMenu";
import { PopoverMenuList } from "./Popover/PopoverMenuList";
import { PopoverMenuItem, type PopoverMenuItemShape } from "./Popover/PopoverMenuItem";

const StyledComboboxInput = styled.input<{ isLoading?: boolean }>`
  background: var(--figma-color-bg);
  border: none;
  border-bottom: 1px solid var(--figma-color-border);
  color: var(--figma-color-text);
  font-size: ${fontSize("regular")};
  height: ${gridSpacePx(7)};
  outline: none;
  padding-bottom: ${gridSpacePx(4)};
  padding-left: ${gridSpacePx(3)};
  padding-right: ${props => (props.isLoading ? gridSpacePx(6.5) : gridSpacePx(3))};
  padding-top: ${gridSpacePx(4)};
  width: 100%;

  &:not(:disabled):focus,
  &:not(:disabled):focus-visible {
    outline: none;
  }
`;

type LoadingProps = {
  /** Is the input loading */
  isLoading?: boolean;
};

/** Input for ComboBox */
export const ComboboxInput = React.forwardRef(function ComboboxInput_(
  props: GetInputPropsOptions & LoadingProps,
  ref: React.RefObject<HTMLInputElement>
) {
  // Spread props from Downshift aren't compatible with Styled Components
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <StyledComboboxInput {...(props as any)} ref={ref} />;
});

/** Background for the combobox loading icon to overlay input text. */
export const ComboboxLoadingBackground = styled.div`
  background-color: var(--figma-color-bg);
  padding: 0 ${gridSpacePx(1)};
  position: absolute;
  right: ${gridSpacePx(1)};
  top: ${gridSpacePx(2.5)};
`;

/** Loading icon for ComboBox */
export const ComboboxLoadingIcon = styled(LoadingIcon)`
  fill: ${props => props.theme.color.labelFaint};
  height: ${gridSpacePx(3)};
  width: ${gridSpacePx(3)};
`;

/** Animates a loading icon in the combobox input. */
export function ComboboxLoading(props: LoadingProps) {
  const { isLoading } = props;

  return (
    <AnimatePresence initial={false}>
      <motion.div
        key="combobox-loading"
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoading ? 1 : 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <ComboboxLoadingBackground>
          <ComboboxLoadingIcon />
        </ComboboxLoadingBackground>
      </motion.div>
    </AnimatePresence>
  );
}

const ComboboxMessage = styled(Text)`
  color: ${props => props.theme.color.labelMuted};
  display: block;
  margin-top: ${gridSpacePx(1)};
  padding: ${gridSpacePx(2)} ${gridSpacePx(4)};
`;

/** Interface for a list of items within a combobox. */
export interface ComboboxItemList<T> {
  /** Unique key for the list of items. */
  key: React.Key;
  /** Optional label for the list of items. */
  label?: string;
  /** List of items in a list within the combobox. */
  items: PopoverMenuItemShape<T>[];
}

interface ComboboxProps<T> extends React.PropsWithChildren {
  /** Styling and anchoring props for the popover content. */
  contentProps?: Popover.PopperContentProps;
  /** Text to display when there are no items. */
  emptyMessage?: string;
  /** List of options for the combobox. */
  items?: PopoverMenuItemShape<T>[];
  /**
   * Multiple lists of options for the combobox separated by an optional label.
   */
  itemLists?: ComboboxItemList<T>[];
  /** If the combobox is loading. */
  isLoading?: boolean;
  /** Any styling for the menu element. */
  menuClassName?: string;
  /** Callback when the menu is opened. */
  onOpen?: () => void;
  /** Callback when the user has queried within the combobox search input. */
  onSearch?: (searchTerm: string) => void;
  /** Callback when the user has selected an item from the combobox. */
  onSelect: (item: PopoverMenuItemShape<T>) => void;
  /** Placeholder text for the combobox search input. */
  placeholder?: string;
  /** The selected item in the combobox. */
  selectedItem?: PopoverMenuItemShape<T>;
}

/** Renders a combobox */
export function Combobox<T>(props: ComboboxProps<T>) {
  const {
    children,
    contentProps,
    emptyMessage,
    isLoading = false,
    items,
    itemLists,
    onSearch,
    onSelect,
    placeholder,
    selectedItem,
  } = props;

  // State that updates to true after isLoading is confirmed for a timeout
  const showLoading = useDelayedLoadingIndicator(isLoading);

  // Controlled open state to prevent Radix and downshift from colliding
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const previousOpenWidth = useRef<number | null>(null);

  // If item lists are provided, flatten into a single list for the combobox as options
  const comboboxItems = useMemo(() => {
    if (!itemLists) {
      return items ?? [];
    }

    const itemListItems = itemLists.reduce((acc, list) => {
      acc.push(...list.items);
      return acc;
    }, [] as PopoverMenuItemShape<T>[]);

    return itemListItems;
  }, [itemLists, items]);

  const {
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    setHighlightedIndex,
    setInputValue,
  } = useCombobox({
    isOpen,
    items: comboboxItems,
    itemToString() {
      // Prevents the selected item from being displayed in the input
      return inputRef.current?.value ?? "";
    },
    onInputValueChange(change) {
      const changedInput = change.inputValue ?? "";

      onSearch?.(changedInput);
    },
    onIsOpenChange(change) {
      // When downshift closes but it isn't communicated to Radix (such as
      // using the keyboard to open the menu or clicking outside of the window)
      if (change.isOpen === false && change.isOpen !== isOpen) {
        setIsOpen(change.isOpen);
      }

      if (change.isOpen && highlightedIndex === -1) {
        const selectedIndex = comboboxItems.findIndex(item => {
          return selectedItem?.key === item.key;
        });
        setHighlightedIndex(selectedIndex !== -1 ? selectedIndex : 0);
      }
    },
    onSelectedItemChange(change) {
      if (!change.selectedItem) {
        return;
      }

      // After selecting an item, delay clearing the input during an
      // animation.
      setTimeout(() => {
        setInputValue("");
      }, 300);

      setIsOpen(false);
      onSelect(change.selectedItem);
    },
    selectedItem,
  });

  // While open and the items change preserve the width of the menu. When the
  // menu closes, reset the preserved width and clear the input value after
  // the animation finishes.
  useLayoutEffect(() => {
    if (isOpen) {
      const contentWidth = contentRef.current?.getBoundingClientRect().width;
      if (contentWidth) {
        previousOpenWidth.current = contentWidth;
      }

      const selectedIndex = comboboxItems.findIndex(item => {
        return selectedItem?.key === item.key;
      });
      setHighlightedIndex(selectedIndex !== -1 ? selectedIndex : 0);
    } else {
      setTimeout(() => {
        previousOpenWidth.current = null;
        setInputValue("");
      }, 200);
    }
  }, [contentRef.current, selectedItem, items, isOpen]);

  // Delay autofocus on the input element while animations play
  useDelayedAutofocus(inputRef, isOpen);

  if (!itemLists && !items) {
    throw new Error("Combobox must have items or itemLists");
  }

  function MenuItem(menuItemProps: { item: PopoverMenuItemShape<T>; index: number }) {
    const { item, index } = menuItemProps;
    return (
      <PopoverMenuItem
        {...item}
        key={item.key}
        isHighlighted={index === highlightedIndex}
        isSelected={item.key === selectedItem?.key}
        itemProps={getItemProps({
          item,
          index,
        })}
      />
    );
  }

  // Increment index between item lists
  let itemIndex = 0;
  let ComboboxItemLists = null;
  if (itemLists && itemLists.length > 0) {
    ComboboxItemLists = itemLists.map(itemList => {
      if (!itemList.items.length) {
        return null;
      }

      const listItems = itemList.items.map(item => {
        return <MenuItem key={item.key} item={item} index={itemIndex++} />;
      });

      return (
        <React.Fragment key={itemList.key}>
          {itemList.label && <ComboboxItemListLabel>{itemList.label}</ComboboxItemListLabel>}
          {listItems}
        </React.Fragment>
      );
    });
  }

  let ComboboxItems = null;
  if (items && items.length > 0) {
    ComboboxItems = comboboxItems.map((item, index) => {
      return <MenuItem key={item.key} item={item} index={index} />;
    });
  }

  return (
    <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
      {/* Remove -1 tabIndex by default since the toggle button enables the popover. */}
      <Popover.Trigger asChild {...getToggleButtonProps({ tabIndex: undefined })}>
        {children}
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          asChild
          align="start"
          hideWhenDetached
          side="bottom"
          sideOffset={gridSpace(1)}
          collisionPadding={panelXPadding()}
          ref={contentRef}
          {...contentProps}
        >
          {/*
           * Wrapper around motion prevents Radix from assuming the popover
           * is off screen and closed.
           */}
          <div
            style={{
              width: previousOpenWidth.current ?? undefined,
              zIndex: 2,
            }}
          >
            {/* Ref errors are suppressed to avoid mounting the combobox when it's closed. */}
            <PopoverMenu isOpen={isOpen} {...getMenuProps(undefined, { suppressRefError: true })}>
              <ComboboxInput
                isLoading={isLoading}
                placeholder={placeholder}
                {...getInputProps(
                  {
                    ref: inputRef,
                  },
                  {
                    suppressRefError: true,
                  }
                )}
              />
              <ComboboxLoading isLoading={showLoading} />
              <PopoverMenuList isOpen={isOpen}>
                {comboboxItems.length === 0 ? (
                  <ComboboxMessage>{emptyMessage || "No items found."}</ComboboxMessage>
                ) : ComboboxItemLists ? (
                  <>{ComboboxItemLists}</>
                ) : (
                  <>{ComboboxItems}</>
                )}
              </PopoverMenuList>
            </PopoverMenu>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

const ComboboxItemListLabel = styled(Text)`
  color: ${color("labelFaint")};
  padding: ${gridSpacePx(1)} ${gridSpacePx(3)};
`;
