import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { PageIcon } from "@linear/orbiter/icons/figma/PageIcon";
import { TextTitle } from "~/components/TextTitle";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { NodeIcon } from "~/components/NodeIcon";
import { NodeSummary } from "~/components/NodeSummary";
import { NodeList, NodeListFlex } from "~/components/NodeList";
import { uiFigma } from "~/store/UIFigma";
import { Scrollable } from "~/components/Scrollable";

export const AddIssueAttachments = observer(function AddIssueAttachments_() {
  if (!uiFigma.selection) {
    return null;
  }

  const hasUnlinkableSelection = !uiFigma.selection.isPage && uiFigma.selection.nodes.length === 0;

  return (
    <IssueAttachmentsContainer column gap={gridSpace(1)} grow={1}>
      <Flex className="w-full" align="center" gap={gridSpace(1)} justify="space-between">
        <TextTitle variant="regularPlus">Attachments</TextTitle>
        {hasUnlinkableSelection ? null : uiFigma.selection.isPage ? (
          <Flex gap={gridSpace(2)} align="center" noMinWidth>
            <PageIcon /> <Text truncate>{uiFigma.currentPage.name}</Text>
          </Flex>
        ) : uiFigma.selection.nodes.length === 1 ? (
          <Flex gap={gridSpace(1)} align="center" noMinWidth>
            <NodeIcon type={uiFigma.selection.nodes[0].type} /> <Text truncate>{uiFigma.selection.nodes[0].name}</Text>
          </Flex>
        ) : (
          <NodeSummary nodes={uiFigma.selection.nodes} />
        )}
      </Flex>
      <AttachmentsListFlex>
        {hasUnlinkableSelection ? (
          <Text color="labelMuted">Invalid selection. Select a frame, section, or page to link.</Text>
        ) : uiFigma.selection.isPage ? (
          <Text color="labelMuted">Issue will be linked to the current page.</Text>
        ) : uiFigma.selection.nodes.length === 1 ? (
          <Text color="labelMuted">
            Issue will be linked to the selected {uiFigma.selection.nodes[0].type === "SECTION" ? "section" : "frame"}.
          </Text>
        ) : (
          <NodeListFlex>
            <NodeList selectableNodes={false} nodes={uiFigma.selection.nodes} />
          </NodeListFlex>
        )}
      </AttachmentsListFlex>
    </IssueAttachmentsContainer>
  );
});

const AttachmentsListFlex = styled(Scrollable)`
  display: flex;
  flex-direction: column;
  flex-basis: 0; // Allow for resizing to scroll
  gap: ${gridSpacePx(2)};
  margin-top: ${gridSpacePx(2)};
`;

const IssueAttachmentsContainer = styled(Scrollable)`
  border-top: 1px solid var(--figma-color-border);
  padding: ${gridSpacePx(5)} var(--panel-x-padding) ${gridSpacePx(1)};
`;
