import React from "react";
import styled from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import { buttonStyles, type ButtonVariant, type ButtonSize } from "../styles/button";

/** Props for the button components */
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  /** Button style type */
  variant?: ButtonVariant;
  /** Applies active highlight styling to the button. */
  active?: boolean;
  /** Button style type */
  size?: ButtonSize;
  /** Optional icon. */
  icon?: React.ReactNode;
  /** Display the icon on the right of the label */
  rightAlignIcon?: boolean;
  /** Icon should be displayed as monotone. Defaults to true. */
  monotoneIcon?: boolean;
  /** The type of the button */
  type?: "button" | "submit";
  /** Is the button disabled */
  disabled?: boolean;
  /** Should button gain focus automatically. */
  autoFocus?: boolean;
  /** Should the button be stretched (width: 100%). Default: false */
  stretched?: boolean;
  /** Disables the button border. Useful when overriding the styling. Default: false */
  disableBorder?: boolean;
} & (
    | {
        /** If the icon the only content in the button? */
        onlyIcon?: false;
        /** Optional aria-label, when onlyButton: false */
        ["aria-label"]?: string;
      }
    | {
        /** If the icon the only content in the button? */
        onlyIcon: true;
        /** Required aria-label, when onlyIcon: true */
        ["aria-label"]: string;
      }
  );

/**
 * The main button component.
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { icon, monotoneIcon = true, rightAlignIcon = false, children, ...rest } = props;

  const iconContent = icon ? <span aria-hidden>{icon}</span> : null;

  return (
    <Component
      ref={ref}
      icon={icon}
      monotoneIcon={monotoneIcon}
      $onlyIcon={!children || props.onlyIcon}
      {...rest}
      rightAlignIcon={rightAlignIcon}
    >
      {rightAlignIcon ? null : iconContent}
      {props.onlyIcon ? null : children}
      {rightAlignIcon ? iconContent : null}
    </Component>
  );
});

const Component = styled.button.withConfig({
  shouldForwardProp: prop =>
    !["monotoneIcon", "variant", "size", "stretched", "active", "onlyIcon"].includes(prop) && isPropValid(prop),
})<ButtonProps & { $onlyIcon?: boolean }>`
  ${buttonStyles.rootCss}
  border: 1px solid transparent;
  position: relative;

  ::placeholder {
    color: ${props => props.theme.color.labelFaint};
  }

  ${props => (props.stretched ? `width: 100%;` : ``)};
  ${props => buttonStyles.variantCss({ variant: props.variant ?? "primary", active: props.active })}
  ${props =>
    buttonStyles.sizeCss({
      size: props.size ?? "normal",
      onlyIcon: props.$onlyIcon,
      isCircle: props.variant === "circle",
    })}
  ${props =>
    buttonStyles.iconCss({
      size: props.size ?? "normal",
      $onlyIcon: props.$onlyIcon,
      $rightAlign: props.rightAlignIcon,
      icon: props.icon,
      monotoneIcon: props.monotoneIcon,
    })};
`;

Component.defaultProps = {
  variant: "primary",
  type: "button",
  size: "normal",
};
