import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { AuthenticationMessage } from "shared/messages";
import { AnimatePresence } from "framer-motion";
import { Flex } from "@linear/orbiter/components/Flex";
import { Text } from "@linear/orbiter/components/Text";
import { Button } from "@linear/orbiter/components/Button";
import { fontSize } from "@linear/orbiter/styles/mixins";
import { store } from "~/store";
import { postPluginMessage } from "~/utils/postPluginMessage";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { useOAuthAuthStringChallenge } from "~/queries/useOAuthAuthStringChallenge";
import { Config } from "~/config";
import { FormButton } from "~/components/FormButton";
import { useOAuthAuthStringAuthentication } from "~/hooks/useOAuthAuthStringAuthentication";
import { uiFigma } from "~/store/UIFigma";
import { useLoadImage } from "~/hooks/useLoadImage";
import { handleExternalClick } from "~/utils/links";
import { MotionFade, motionFadeAnimation } from "./MotionFade";

/** Component that will only render it's children when we have authentication */
export const AuthenticationRequired = observer(function Authentication_(props: React.PropsWithChildren) {
  const [authString, setAuthString] = useState<string>();
  const oAuthAuthStringChallenge = useOAuthAuthStringChallenge({ scope: Config.OAUTH_SCOPE });

  const handleLogin = useCallback(async () => {
    const result = await oAuthAuthStringChallenge.trigger();
    setAuthString(result?.authString);
  }, []);

  const handleReset = useCallback(() => {
    setAuthString(undefined);
  }, []);

  function handleAccessToken(accessToken: string) {
    void store.setUser(accessToken);

    setAuthString(undefined);

    postPluginMessage(
      new AuthenticationMessage({
        accessToken,
      })
    );
  }

  const loginImageSrc =
    uiFigma.editorTheme === "light" ? "/images/login/login-graph-light.png" : "/images/login/login-graph-dark.png";
  const logoImageSrc = "/images/login/login-linear-icon.png";

  // Load login images manually before showing fade-in animation
  const hasLoadedLoginImages = useLoadImage([loginImageSrc, logoImageSrc]);

  // Poll the OAuth authentication endpoint with the challenge authString
  useOAuthAuthStringAuthentication({
    authString,
    onSuccess: handleAccessToken,
    onReset: handleReset,
  });

  if (store.accessToken) {
    return <>{props.children}</>;
  }

  // This state should only last a few ms while we wait for the plugin process to send initial state, rendering
  // nothing prevents a flash of the login button.
  if (store.initializingAuth) {
    return null;
  }

  return (
    <AnimatePresence mode="popLayout">
      {!authString ? (
        // Wait to start the animation until the image is loaded if we haven't
        // loaded it yet
        <MotionFade
          animate={hasLoadedLoginImages ? motionFadeAnimation.animate : {}}
          className="w-full h-full"
          key="login"
        >
          <Flex className="h-full" align="center" column>
            <LoginImage
              alt="An image showing outlines of Figma frames as rectangles with lines pointing down in curved arcs down towards the Linear logo."
              src={loginImageSrc}
            />
            <LoginFlexContainer align="center" className="h-full" column>
              <Flex gap={gridSpace(1)}>
                <LoginLinkImage alt="Linear logo" src="/images/login/login-linear-icon.png" />
              </Flex>
              <Flex align="center" column gap={gridSpace(2)}>
                <Text variant="regularPlus" align="center">
                  Connect to Linear
                </Text>
                <Text align="center" color="labelMuted">
                  Create and link Linear issues to your pages, frames, and sections without leaving Figma
                </Text>
              </Flex>
              <LoginButtonContainer>
                <FormButton className="w-full" onClick={handleLogin}>
                  Login with Linear
                </FormButton>
              </LoginButtonContainer>
            </LoginFlexContainer>
            <PrivacyNoticeFlex alignSelf="flex-end" shrink={0} className="mb-6 mt-3">
              <Text align="center" color="labelMuted">
                By connecting with Linear, you agree to the plugin{" "}
                <StyledAnchorTag
                  href="https://linear.app/docs/figma#privacy-policy"
                  onClick={handleExternalClick}
                  rel="noopener noreferrer"
                >
                  privacy policy
                </StyledAnchorTag>
              </Text>
            </PrivacyNoticeFlex>
          </Flex>
        </MotionFade>
      ) : (
        <MotionFade className="w-full" key="loading-oauth">
          <LoadingFlexContainer align="center" column gap={gridSpace(4)}>
            <Text align="center" color="labelMuted">
              Connecting Linear account in your browser…
            </Text>
            <CancelLoginButton variant="link" onClick={handleReset}>
              Cancel
            </CancelLoginButton>
          </LoadingFlexContainer>
        </MotionFade>
      )}
    </AnimatePresence>
  );
});

const LoginImage = styled.img`
  height: 263px;
`;

const LoginFlexContainer = styled(Flex)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${gridSpacePx(60)};
  width: 100%;
`;

const PrivacyNoticeFlex = styled(LoginFlexContainer)`
  align-self: flex-end;
  margin-bottom: ${gridSpacePx(20)};
`;

const LoginLinkImage = styled.img`
  width: 75px;
`;

const LoginButtonContainer = styled.div`
  margin-top: ${gridSpacePx(8)};
  max-width: ${gridSpacePx(75)};
  width: 100%;
`;

const CancelLoginButton = styled(Button)`
  color: var(--color-accent);
  font-size: ${fontSize("regular")};
`;

const StyledAnchorTag = styled.a`
  color: var(--figma-color-text-secondary);
  text-decoration: underline;
`;

const LoadingFlexContainer = styled(LoginFlexContainer)`
  margin-top: ${gridSpacePx(40)};
  max-width: ${gridSpacePx(40)};
`;
