import * as React from "react";
type Property =
  | "offsetHeight"
  | "scrollHeight"
  | "clientHeight"
  | "offsetWidth"
  | "clientWidth"
  | "scrollWidth"
  | "offsetLeft"
  | "clientRectWidth"
  | "clientRectHeight"
  | "clientRectRight"
  | "clientRectLeft";
/**
 * A hook that returns the requested size property of the component associated with the reference.
 *
 * @param ref React reference for the element to measure.
 * @param property Which element property to return. All properties return rounded pixel values, except for
 * clientRectWidth an clientRectHeight, which calculate the width and height using `Element.getBoundingClientRect()`.
 * You should use clientRectWidth and clientRectHeight for any animation that you don't want to pixel-shift when
 * changing the animated width or height to "auto".
 * @returns The height/width of the measured component or undefined, if the property could not be determined (for example during the first render).
 */
export function useComponentSize<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null> | null,
  property: Property,
  deps?: React.DependencyList
): number | undefined {
  const [size, setSize] = React.useState(ref?.current ? getComponentSize(ref?.current, property) : undefined);

  React.useLayoutEffect(() => {
    const current = ref?.current;
    if (!current) {
      setSize(undefined);
      return;
    }

    const handleResize = (element?: T) => {
      setSize(getComponentSize(element || current, property));
    };

    handleResize();

    return observeComponentResize(ref, handleResize);
  }, [ref, ref?.current, ...(deps || [])]);

  return size;
}

/**
 * A hook that returns the `clientHeight` of the component associated with the reference.
 *
 * @param ref React reference for the element to measure.
 * @returns The height of the measured component or undefined, if the height could not be determined (for example during the first render).
 */
export function useComponentHeight<T extends HTMLElement>(ref: React.MutableRefObject<T | null>) {
  return useComponentSize<T>(ref, "clientHeight");
}

/**
 * A hook that returns the `clientWidth` of the component associated with the reference.
 *
 * @param ref React reference for the element to measure.
 * @returns The width of the measured component or undefined, if the width could not be determined (for example during the first render).
 */
export function useComponentWidth<T extends HTMLElement>(ref: React.MutableRefObject<T | null> | null) {
  return useComponentSize<T>(ref, "clientWidth");
}

/**
 * Returns whether the element the ref points to has overflow or not by comparing client(Width/Height) to scroll(Width/Height).
 *
 * @param ref React ref for the element to measure
 * @returns if the element has overflowing content or not
 */
export function useHasOverflow<T extends HTMLElement>(ref: React.MutableRefObject<T | null> | null) {
  const [hasOverflow, setHasOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    const current = ref?.current;
    if (!current) {
      setHasOverflow(false);
      return;
    }

    // Take the first measurement in the useLayoutEffect so we have it before first paint
    setHasOverflow(elementHasOverflow(current));

    const handleResize = (element?: T) => {
      if (element) {
        setHasOverflow(elementHasOverflow(element));
      }
    };

    handleResize();
    return observeComponentResize(ref, handleResize);
  }, [ref?.current]);

  return hasOverflow;
}

const elementHasOverflow = (el: HTMLElement) => {
  return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;
};

/**
 * Extract a specific size from a given HTML element
 *
 * @param element The HTML element to measure
 * @param property The name of the size to measure
 * @returns The size of the element for the given property.
 */
export function getComponentSize<T extends HTMLElement>(element: T | null, property: Property) {
  if (!element) {
    return undefined;
  }
  let value = 0;
  if (property === "scrollHeight") {
    const currentHeight = element.style.height;
    element.style.height = "0px";
    value = element.scrollHeight;
    element.style.height = currentHeight;
  } else if (property === "scrollWidth") {
    const currentWidth = element.style.width;
    element.style.width = "0px";
    value = element.scrollWidth;
    element.style.width = currentWidth;
  } else if (
    property === "clientRectWidth" ||
    property === "clientRectHeight" ||
    property === "clientRectRight" ||
    property === "clientRectLeft"
  ) {
    const rect = element.getBoundingClientRect();
    if (property === "clientRectWidth") {
      return rect.width;
    }
    if (property === "clientRectHeight") {
      return rect.height;
    }
    if (property === "clientRectRight") {
      return rect.right;
    }
    if (property === "clientRectLeft") {
      return rect.left;
    }
  } else {
    value = element[property];
  }
  return value;
}

/** Observe size changes in an HTML element */
export function observeComponentResize<T extends HTMLElement>(
  ref: React.MutableRefObject<T | null> | null,
  handleResize: (element?: T) => void
) {
  const current = ref?.current;

  if (ref == null || current == null) {
    return;
  }

  if (typeof ResizeObserver === "function") {
    let observer: ResizeObserver | null = new ResizeObserver(() => {
      window.requestAnimationFrame(() => {
        if (current === ref?.current) {
          handleResize(current);
        }
      });
    });
    observer.observe(current);

    return () => {
      observer?.disconnect();
      observer = null;
    };
  } else {
    const listener = () => handleResize();
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }
}
