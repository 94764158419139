/**
 * Is using iPad
 */
export const isiPad =
  typeof navigator !== "undefined" &&
  (/iPad/.test(navigator.userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1));

/**
 * Detect if user is on mobile sized screen/window.
 * If possible, use useScreenSize hook instead.
 */
export const isMobileSize = () => typeof window !== "undefined" && window.innerWidth < 640;

/**
 * Detects Google Pixel. Needs to be special cased since it doesn't respect the pointer: coarse media query.
 */
const isPixel = typeof navigator !== "undefined" && /\(Linux; Android \d+\; Pixel \d+/i.test(navigator.userAgent);

/** Local storage key for force pointer mode override */
export const FORCE_POINTER_MODE_LOCAL_STORAGE_KEY = "FORCE_POINTER_MODE";
/** Values for force pointer mode override */
export const FORCE_POINTER_MODE = {
  FINE: "fine",
  COARSE: "coarse",
};

/**
 * Returns true if the device is a pure touch device. It respects a forced override if `FORCE_POINTER_MODE`
 * local storage key is set to FORCE_POINTER_MODE.COARSE or FORCE_POINTER_MODE.FINE.
 */
export const isPureTouchDevice = (() => {
  let forcePointerMode;
  try {
    const storedValue = localStorage.getItem(FORCE_POINTER_MODE_LOCAL_STORAGE_KEY);
    forcePointerMode = storedValue ? JSON.parse(storedValue) : undefined;
  } catch (e) {
    // Ignore
  }
  if (forcePointerMode !== undefined) {
    return forcePointerMode === FORCE_POINTER_MODE.COARSE;
  }
  return isPixel || (typeof window !== "undefined" && window.matchMedia?.("(pointer: coarse)")?.matches);
})();

/**
 * Is using Apple Safari.
 */
export const isSafari = typeof navigator !== "undefined" && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

/**
 * Is using Brave browser.
 */
export const isBrave = typeof navigator !== "undefined" && window.navigator.brave?.isBrave.name === "isBrave";

/**
 * Is using Apple Safari.
 */
export function isSafari16(): boolean {
  return /(\s|^)AppleWebKit\/[\d\.]+\s+\(.+\)\s+Version\/16(\.|$|\s)/i.test(navigator.userAgent);
}

/**
 * Whether the browser is Firefox.
 */
export const isFirefox = typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

/**
 * Whether the browser is Chromium-based.
 */
export const isChrome = typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().indexOf("chrome") > -1;

/**
 * Is using macOS.
 */
export const isMac = typeof navigator !== "undefined" && navigator.platform === "MacIntel";

/**
 * Is using a PWA on desktop.
 */
export const isPWA =
  (typeof navigator !== "undefined" && "standalone" in navigator && navigator.standalone === true) ||
  (typeof window !== "undefined" && window.matchMedia?.("(display-mode: standalone)").matches);

/**
 * Is using iOS
 */
export const isiOS = typeof navigator !== "undefined" && (/iPhone|iPod/.test(navigator.userAgent) || isiPad);

/**
 * Is using Windows.
 */
export const isWindows = typeof navigator !== "undefined" && "platform" in navigator && navigator.platform === "Win32";

/**
 * Is using Linux.
 */
export const isLinux =
  typeof navigator !== "undefined" && "platform" in navigator && navigator.platform.includes("Linux");

/**
 * Is using Apple device.
 */
export const isAppleDevice = isiOS || isMac || isiPad;
