import styled from "styled-components";

type JustifyValues =
  | "center"
  | "stretch"
  | "space-around"
  | "space-between"
  | "space-evenly"
  | "flex-start"
  | "flex-end";
type AlignValues = "stretch" | "center" | "baseline" | "flex-start" | "flex-end";

/** Props for the Flex component */
export type FlexProps = {
  /** Set `flex-direction` to `column` */
  column?: boolean;
  /** Sets flex direction to either `row-reverse` or `column-reverse` (depending on direction of `column` prop) */
  reverse?: boolean;
  /** Center all content. Shortcut for `align=center` and `justify=center` combination. */
  center?: boolean;
  /** Set `align-items`. */
  align?: AlignValues;
  /** Set `align-self`. */
  alignSelf?: AlignValues;
  /** Set `justify-content`. */
  justify?: JustifyValues;
  /** Set `flex=1` */
  auto?: boolean;
  /** The classname to apply to the container. */
  className?: string;
  /** The children to render. */
  children?: React.ReactNode;
  /** Set `flex-grow` value. */
  grow?: number;
  /** Set `flex-shrink` value. */
  shrink?: number;
  /** Set `flex-wrap` value. */
  wrap?: boolean;
  /** Set `display=inline-flex`. */
  inline?: boolean;
  /** Mark as disabled */
  disabled?: boolean;
  /** Space between items */
  gap?: number;
  /** Space between wrapped lines, defaults to gap if not provided */
  wrapGap?: number;
  /** Hides overflow */
  overflowHidden?: boolean;
  /**
   * Set min-width: 0px to truncate child text elements.
   * https://css-tricks.com/flexbox-truncated-text/#aa-the-solution-is-min-width-0-on-the-flex-child
   */
  noMinWidth?: boolean;
  /** Set min-height: 0px to truncate child text elements. */
  noMinHeight?: boolean;
};

/** Prop keys for the Flex component */
export const flexPropKeys = [
  "column",
  "reverse",
  "center",
  "align",
  "alignSelf",
  "justify",
  "auto",
  "grow",
  "shrink",
  "wrap",
  "inline",
  "disabled",
  "gap",
  "wrapGap",
  "overflowHidden",
  "noMinWidth",
  "noMinHeight",
];

/**
 * Generic flexbox div component.
 */
export const Flex = styled("div").withConfig({
  shouldForwardProp: prop => !flexPropKeys.includes(prop),
})<FlexProps>`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  flex: ${({ auto }) => (auto ? "1 1 auto" : "initial")};
  flex-direction: ${({ column, reverse }) => {
    if (column) {
      return reverse ? "column-reverse" : "column";
    }
    return reverse ? "row-reverse" : "row";
  }};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  ${({ center }) => (center === true ? `align-items: center; justify-content: center;` : "")};
  ${({ grow }) => (typeof grow === "number" ? `flex-grow: ${grow};` : "")};
  ${({ shrink }) => (typeof shrink === "number" ? `flex-shrink: ${shrink};` : "")};
  ${({ wrap }) => (wrap ? "flex-wrap: wrap;" : "")};
  ${({ alignSelf }) => (alignSelf ? `align-self: ${alignSelf};` : "")};
  ${({ overflowHidden }) => (overflowHidden ? `overflow: hidden;` : "")};
  ${({ gap }) => (typeof gap === "number" ? `gap: ${gap}px` : "")};
  ${({ wrapGap, column }) => (typeof wrapGap === "number" ? `${column ? "column" : "row"}-gap: ${wrapGap}px` : "")};
  ${({ noMinWidth }) => (noMinWidth ? "min-width: 0px" : "")};
  ${({ noMinHeight }) => (noMinHeight ? "min-height: 0px" : "")};
`;

/** A flex spacer */
export const FlexSpacer = styled.div`
  display: flex;
  flex: auto;
`;
