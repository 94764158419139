/**
 * Return true if active element is textual input.
 */
export const activeElementEditable = () => {
  const { activeElement } = window.document;
  return activeElement ? isTextualInput(activeElement) : false;
};

/**
 * Determine if element or event target is a textual input.
 *
 * Used to disable keyboard shortcuts when user is expected to input text.
 *
 * @param elem Target element.
 */
export const isTextualInput = (
  elem: Element | EventTarget | null
): elem is HTMLTextAreaElement | HTMLElement | HTMLInputElement => {
  if (
    elem === null ||
    elem instanceof HTMLTextAreaElement ||
    (elem as HTMLElement).contentEditable === "true" ||
    (elem instanceof HTMLInputElement &&
      !["checkbox", "radio", "button", "submit", "reset"].includes(elem.type) &&
      !elem.disabled)
  ) {
    return true;
  }
  return false;
};
