/**
 * Helper class for browser related functionality.
 */
export class Browser {
  /**
   * Is using Apple Safari.
   */
  public static readonly isSafari: boolean =
    typeof navigator !== "undefined" && /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  /**
   * Is using Apple Safari.
   */
  public static get isSafari16(): boolean {
    return Browser.memoize(Browser.isSafari16Fnc);
  }

  /**
   * Is using Brave browser.
   */
  public static readonly isBrave: boolean =
    typeof navigator !== "undefined" && navigator.brave?.isBrave.name === "isBrave";

  /**
   * Whether the browser is Firefox.
   */
  public static readonly isFirefox: boolean =
    typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().indexOf("firefox") > -1;

  /**
   * Whether the browser is Chromium-based.
   */
  public static readonly isChrome: boolean =
    typeof navigator !== "undefined" && navigator.userAgent.toLowerCase().indexOf("chrome") > -1;

  /**
   * Detect if user is on mobile sized screen/window.
   * If possible, use useScreenSize hook instead.
   */
  public static readonly isMobileSize: boolean = typeof window !== "undefined" && window.innerWidth < 640;

  /**
   * Is using macOS.
   */
  public static readonly isMac: boolean = typeof navigator !== "undefined" && navigator.platform === "MacIntel";

  /**
   * Is using iPad
   */
  public static readonly isiPad: boolean =
    typeof navigator !== "undefined" &&
    (/iPad/.test(navigator.userAgent) || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1));

  /**
   * Is using iOS
   */
  public static readonly isiOS: boolean =
    typeof navigator !== "undefined" && (/iPhone|iPod/.test(navigator.userAgent) || Browser.isiPad);

  /**
   * Is using Apple device.
   */
  public static readonly isAppleDevice: boolean = Browser.isiOS || Browser.isMac || Browser.isiPad;

  /**
   * Is using Android phone.
   */
  public static readonly isAndroid: boolean = typeof navigator !== "undefined" && /android/i.test(navigator.userAgent);

  /**
   * Is using Windows.
   */
  public static readonly isWindows: boolean =
    typeof navigator !== "undefined" && "platform" in navigator && navigator.platform === "Win32";

  /**
   * Is using Linux.
   */
  public static readonly isLinux: boolean =
    typeof navigator !== "undefined" && "platform" in navigator && navigator.platform.includes("Linux");

  /**
   * Detects Google Pixel. Needs to be special cased since it doesn't respect the pointer: coarse media query.
   */
  public static readonly isPixel: boolean =
    typeof navigator !== "undefined" && /\(Linux; Android \d+\; Pixel \d+/i.test(navigator.userAgent);

  /**
   * Is using a mobile device.
   */
  public static readonly isMobile: boolean =
    typeof navigator !== "undefined" && /mobile/i.test(navigator.userAgent) && (Browser.isiOS || Browser.isAndroid);

  /**
   * Is using a PWA on desktop.
   */
  public static readonly isPWA: boolean =
    (typeof navigator !== "undefined" && "standalone" in navigator && navigator.standalone === true) ||
    (typeof window !== "undefined" && window.matchMedia?.("(display-mode: standalone)").matches);

  /**
   * Returns true if the device is a pure touch device. It respects a forced override if `FORCE_POINTER_MODE`
   * local storage key is set to FORCE_POINTER_MODE.COARSE or FORCE_POINTER_MODE.FINE.
   */
  public static get isPureTouchDevice(): boolean {
    if (Browser._isPureTouch === undefined) {
      Browser._isPureTouch = Browser.isPureTouchDeviceFnc();
    }
    return Browser._isPureTouch;
  }
  private static _isPureTouch: boolean | undefined;

  /** Local storage key for force pointer mode override */
  public static readonly FORCE_POINTER_MODE_LOCAL_STORAGE_KEY = "FORCE_POINTER_MODE";
  /** Values for force pointer mode override */
  public static readonly FORCE_POINTER_MODE = {
    FINE: "fine",
    COARSE: "coarse",
  };

  private static isSafari16Fnc(): boolean {
    return /(\s|^)AppleWebKit\/[\d\.]+\s+\(.+\)\s+Version\/16(\.|$|\s)/i.test(navigator.userAgent);
  }

  private static isPureTouchDeviceFnc(): boolean {
    let forcePointerMode;
    try {
      const storedValue = localStorage.getItem(Browser.FORCE_POINTER_MODE_LOCAL_STORAGE_KEY);

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      forcePointerMode = storedValue ? JSON.parse(storedValue) : undefined;
    } catch {
      // Ignore
    }
    if (forcePointerMode !== undefined) {
      return forcePointerMode === Browser.FORCE_POINTER_MODE.COARSE;
    }

    return (
      Browser.isPixel === true || (typeof window !== "undefined" && window.matchMedia?.("(pointer: coarse)")?.matches)
    );
  }

  private static memoize(callback: () => boolean) {
    if (Browser.memo === undefined) {
      Browser.memo = new Map();
    }
    let result = Browser.memo.get(callback);
    if (result === undefined) {
      result = callback();
      Browser.memo.set(callback, result);
    }
    return result;
  }

  private static memo: Map<object, boolean> | undefined;
}
