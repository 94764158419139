import { ColorConverter } from "../utils/ColorConverter";
import { numericHash } from "../utils/hash";

/** Helpers for the avatar component */
export class AvatarHelper {
  /**
   * Returns a consistent background color for the initials of a model without an avatar.
   *
   * @param modelId: The id of the model to compute the color for.
   * @returns A color.
   */
  public static initialsBackgroundColor(modelId: string): string {
    return colors[numericHash(modelId) % colors.length];
  }

  /**
   * Returns the initials for a name
   *
   * @param nameOrEmail: The name or email address to compute the initials for.
   * @returns A two character string.
   */
  public static initials(nameOrEmail: string) {
    const startsWithLetter = /^\p{Letter}/u;
    const name = nameOrEmail.includes("@") ? nameOrEmail.split("@")[0].replace(/\./, " ") : nameOrEmail;
    const matches = (name.match(/\S+/g) || []).filter(group => startsWithLetter.test(group));
    const allInitials = matches.map(match => match[0]);

    let letters = name.slice(0, 2);
    if (matches.length > 2) {
      // If there are more than two matches, use the first and last two initials (ie. a double surname)
      letters = allInitials[0] + allInitials.slice(-2).join("");
    } else if (matches.length === 2) {
      // If there are only two matches, use the first and last initial
      letters = allInitials[0] + allInitials[allInitials.length - 1];
    }

    return !letters.match(/[A-z\u00C0-\u00ff0-9]+/i) ? null : letters.toUpperCase();
  }
}

const colors = Array.from({ length: 36 }, (_, i) => ColorConverter.toCss("RGB", [55, 60, (10 * i) % 360, 1]));
