import * as React from "react";
import styled from "styled-components";
import { highlight, transitionSpeed } from "../styles/styled";
import { Button, type ButtonProps } from "./Button";

/** Props for IconButton. */
export type IconButtonProps = Omit<ButtonProps, "onlyIcon" | "children" | "disableBorder"> & {
  /** Icon to display in the button. */
  children?: React.ReactNode;
  /** Aria label for the button. */
  "aria-label": string;
  /** Style SVG stroke instead of fill. */
  stroke?: boolean;
  /** Applies hover styles to button. Defaults to true. */
  $hoverStyles?: boolean;
  /** Applies hover styles to svg in button. Defaults to true. */
  $hoverSvgStyles?: boolean;
  /** Kind of button. Defaults to "borderless". */
  variant?: "borderless" | "secondary";
};

const IconButtonInner = React.forwardRef((props: IconButtonProps, ref: React.RefObject<HTMLButtonElement>) => {
  const { children, icon, variant = "borderless", ...rest } = props;
  return (
    <Button size="medium" variant={variant} monotoneIcon={false} {...rest} ref={ref} onlyIcon icon={icon || children} />
  );
});

/**
 * IconButton is a button that contains an SVG icon and no label
 */
export const IconButton = styled(IconButtonInner)`
  padding: 0 2px;
  box-shadow: none;
  &:enabled:${highlight} {
    ${props =>
      props.$hoverSvgStyles !== false
        ? `svg:not(.color-override) {
  stroke: ${props.stroke ? props.theme.color.labelTitle : "none"};
  fill: ${!props.stroke ? props.theme.color.labelTitle : "none"};
  transition-duration: ${transitionSpeed("highlightFadeIn")};
        }`
        : ""}
  }

  ${props =>
    props.$hoverSvgStyles !== false
      ? `svg {
    transition-property: ${props.stroke ? "stroke" : "fill"};
    transition-duration: ${transitionSpeed("highlightFadeOut")};
  }`
      : ""}

  ${props =>
    props.active
      ? `background-color: ${props.theme.color.controlTertiaryHover};

    ${
      props.$hoverSvgStyles !== false
        ? `svg:not(.color-override) { ${props.stroke ? "stroke" : "fill"}: ${props.theme.color.labelTitle}; }`
        : ""
    }
  `
      : ""}
`;
