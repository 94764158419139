// @ts-ignore Fixes web build
import type { ThemedStyledProps } from "styled-components";
import { type ScreenSize, screenSizeBreakPoints } from "../hooks/useScreenSize";
import { ColorConverter } from "../utils/ColorConverter";
import { Browser } from "../utils/browser";
import type { Color } from "./Theme";

export { zIndex } from "./zIndex";

const maxWidthQuery = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;
const minWidthQuery = (maxWidth: number) => `@media (min-width: ${maxWidth}px)`;

/**
 * Media queries for different screens sizes.
 */
export const screenSize: Record<ScreenSize, string> = {
  /** Media query to target only phones-sized screens. */
  phone: maxWidthQuery(screenSizeBreakPoints.phone),
  /** Media query to target only tablet-sized screens and lower. */
  tablet: maxWidthQuery(screenSizeBreakPoints.tablet),
  /** Media query to target only laptop-sized screens and lower. */
  laptop: maxWidthQuery(screenSizeBreakPoints.laptop),
  /** Media query to normal sized desktop and up. */
  normal: minWidthQuery(screenSizeBreakPoints.laptop + 1),
  /** Media query to only target large desktop-sized. */
  large: minWidthQuery(screenSizeBreakPoints.large),
  /** Media query to only target xlarge viewports. */
  xlarge: minWidthQuery(screenSizeBreakPoints.xlarge),
};

/**
 * Returns "hover" on a non-touch device and "active" on a touch device. When the user presses an element on a
 * touch device, the element will receive a sticky "hover", which is not ideal if the intention of the hover pseudo
 * selector is to highlight the element. Use `&:${highlight} {...}` whenever you want to write CSS that highlights an
 * element instead of using `&:hover {...}`.
 */
export const highlight = Browser.isPureTouchDevice ? "active" : "hover";

/**
 * Helper for styled-component which makes it more convenient to get a color value with props function.
 *
 * @param colorName Name of the color
 */
export const color = (colorName: keyof Color) => (props: ThemedStyledProps) => props.theme.color[colorName];

/**
 * Helper for styled-component which always returns the baseTheme version of any color
 *
 * @param colorName Name of the color
 */
export const baseColor = (colorName: keyof Color) => (props: ThemedStyledProps) =>
  props.theme.baseTheme?.color[colorName] ?? props.theme.color[colorName];

/**
 * Helper for styled-component which makes it more convenient to get a font family
 *
 * @param fontType Type of font
 */
export const fontFamily = (fontType: "regular" | "monospace" | "emoji" | "display", fallback?: string) =>
  `var(--font-${fontType}${fallback ? `,${fallback}` : ""})`;

/**
 * Helper for styled-component which makes it more convenient to get a shadow
 *
 * @param fontType Type of shadow
 */
export const shadow = (level: ShadowLevels) => (props: ThemedStyledProps) =>
  props.theme[ShadowLevelToDefaultTheme[level]];

/**
 * Helper for styled-component which makes it more convenient to get a transition speed
 *
 * @param speed Speed of transition
 */
export const transitionSpeed = (
  speed: "highlightFadeIn" | "highlightFadeOut" | "quickTransition" | "regularTransition" | "slowTransition"
) => `var(--speed-${speed})`;

/**
 * Helper for styled-component which makes it more convenient to get a color mix value with props function.
 *
 * @param color1 Name of the first color
 * @param color2 Name of the second color
 * @param alpha Number between 0-1 used to mix the colors
 */
export const colorMix =
  (colorName1: keyof Color, colorName2: keyof Color, alpha: number) => (props: ThemedStyledProps) => {
    const c1 = ColorConverter.fromCss(props.theme.color[colorName1]);
    const c2 = ColorConverter.fromCss(props.theme.color[colorName2]);
    const mix = ColorConverter.mix(c1, c2, alpha);
    return ColorConverter.toCss(props.theme.colorFormat, mix);
  };

const ShadowLevelToDefaultTheme = {
  low: "shadowLow",
  medium: "shadowMedium",
  high: "shadowHigh",
  page: "shadowPage",
  inset: "shadowInset",
} as const;

export type ShadowLevels = keyof typeof ShadowLevelToDefaultTheme;
