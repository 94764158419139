/**
 * Used to guarantee exhaustiveness of switch cases. Use `notReachable` in frontend code instead when the checked value is common with backend and can be API supplied.
 *
 * Example usage:
 *
 * public nameForType(type: EstimationType): string {
 *   switch (type) {
 *     case EstimationType.fibonacci:
 *       return "Fibonacci";
 *     case EstimationType.linear:
 *       return "Linear";
 *     default:
 *       throw new UnreachableCaseError(type)
 *   }
 * }
 *
 * The above code will fail to compile if the switch isn't exhaustive.
 */
export class UnreachableCaseError extends Error {
  public constructor(val: never) {
    super(`Unreachable case: ${val as string}`);
  }
}

/**
 * Used to guarantee exhaustiveness of switch cases without throwing an error. Example usage:
 *
 * public nameForType(type: EstimationType): string {
 *   switch (type) {
 *     case EstimationType.fibonacci:
 *       return "Fibonacci";
 *     case EstimationType.linear:
 *       return "Linear";
 *     default:
 *       notReachable(type);
 *       return "Default value"
 *   }
 * }
 *
 * The above code will fail to compile if the switch isn't exhaustive.
 */
export function notReachable(val: never) {
  return new UnreachableCaseError(val);
}
