import styled from "styled-components";
import { Button } from "@linear/orbiter/components/Button";
import { fontSize } from "@linear/orbiter/styles/mixins";

export const LinkButton = styled(Button)`
  border: none;
  font-size: ${fontSize("regular")};
  font-weight: var(--font-weight-medium);
  height: auto;
  min-width: 0;
  padding: 0;
`;

LinkButton.defaultProps = {
  variant: "link",
};
