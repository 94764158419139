import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useComponentSize } from "@linear/orbiter/hooks/useComponentSize";
import { gridSpacePx } from "~/styles/gridSpace";

interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  delayAutoFocus?: number;
}

export function Textarea(props: TextareaProps) {
  const { autoFocus, delayAutoFocus, onChange, ...restProps } = props;

  const [value, setValue] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const height = useComponentSize(textareaRef, "scrollHeight", [value]);

  useLayoutEffect(() => {
    /**
     * Delays autofocus to prevent browsers from trying to make focused inputs
     * immediately available. Without this, animations that reveal autofocused
     * textareas will break.
     */
    setTimeout(() => {
      if (delayAutoFocus) {
        textareaRef.current?.focus();
      }
    }, delayAutoFocus);
  }, []);

  return (
    <StyledTextarea
      autoFocus={delayAutoFocus !== undefined ? false : autoFocus}
      {...restProps}
      onChange={e => {
        setValue(e.target.value);
        onChange?.(e);
      }}
      $height={height}
      ref={textareaRef}
    />
  );
}

export const StyledTextarea = styled.textarea<{ $height?: number; $inlineEditing?: boolean }>`
  background: transparent;
  border-radius: 3px;
  border: none;
  color: ${props => props.theme.color.labelTitle};
  cursor: text;
  font-size: 1rem;
  height: ${props => props.$height}px;
  line-height: 1.6em;
  overflow: hidden;
  outline: 1px solid transparent;
  padding: ${gridSpacePx(1)} ${gridSpacePx(2)};
  resize: none;
  user-select: text;
  width: 100%;
  word-break: break-word;

  &:not(:disabled):hover {
    outline: 1px solid var(--figma-color-border);
  }

  &:not(:disabled):focus-visible {
    box-shadow: none;
    outline: 1px solid var(--color-accent);
  }

  &::placeholder {
    color: ${props => props.theme.color.labelFaint};
  }
`;
