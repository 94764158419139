import type { ColorConverter } from "../../utils/ColorConverter";
import { ThemeHelper } from "../../utils/ThemeHelper";

export const lightThemeRefresh = (colorFormat: ColorConverter.ColorFormat = "RGB") =>
  ThemeHelper.generateTheme({
    base: [98.7, 0, 282.86346318829925],
    accent: [53, 52.26, 286.91],
    contrast: 30,
    colorFormat,
  });

export const lightThemeRefreshHighContrast = (colorFormat: ColorConverter.ColorFormat = "RGB") =>
  ThemeHelper.generateTheme({
    base: [98.7, 0, 282.86346318829925],
    accent: [53, 52.26, 286.91],
    contrast: 90,
    colorFormat,
  });
