import { Config } from "../config";

/**
 * Match all options for issue identifiers.
 */
export type IssueIdentifierMatchAllOptions = {
  /** Specific team keys that the identifier has to match. Commonly used to only match existing teams. */
  teamKeys?: string[];
  /** Specific word boundary characters we might want to exclude in certain scenarios */
  excludedWordBoundaries?: string[];
  /** If provided the matching will laxer and don't require a hyphen. */
  matchWithoutHyphen?: boolean;
};

/**
 * Match options for issue identifiers.
 */
export type IssueIdentifierMatchOptions = {
  /** Match the issue identifier as the only things in the string. */
  exact?: boolean;
  /**
   * Unbounded matching of the issue identifier. Can be used to tack on custom boundaries.
   * This can match things like STALIN-123 to LIN-123, so be careful.
   * WARNING: Should probably only be used with teamKeys specified!
   * */
  open?: boolean;
  /** Regular expression flags, eg.: gi */
  flags?: string;
} & IssueIdentifierMatchAllOptions;

/**
 * Result of a successful issue identifier match.
 */
export type IssueIdentifierMatchResult = { rawIdentifier: string; identifier: string; teamKey: string; number: number };

/** Helper functions for issues. */
export class IssueHelper {
  /** Sensible length for menus */
  public static visibleTitleLengthInMenus = 100;

  /** Sensible length for a good title */
  public static sensibleTitleLength = 200;

  /** Maximum length for title */
  public static maxTitleLength = 512;

  /** Maximum length for an identifier */
  public static maxKeyLength = 7;

  /** Fixes up an issues title by removing unwanted characters and trimming the result. */
  public static fixupTitle(title: string): string {
    return title
      .replace(/[\n\t]/g, " ")
      .replace(/( ){2,}/g, " ")
      .trim();
  }

  private static identifierRegexCoreString(options?: IssueIdentifierMatchAllOptions) {
    const hyphenMatch = options?.matchWithoutHyphen ? "[-]?" : "-";
    return `((${
      options?.teamKeys ? options.teamKeys.join("|") : `\\w{1,${this.maxKeyLength}}`
    })${hyphenMatch}([0-9]{1,9}))`;
  }

  /**
   * Generates a regular expression string capturing all issue identifiers for the provided team identifiers
   * or any character set if team identifiers are not provided.
   */
  public static identifierRegexString(options?: IssueIdentifierMatchOptions) {
    const coreRegex = this.identifierRegexCoreString(options);

    if (options?.open) {
      return coreRegex;
    }

    if (options?.exact) {
      return `^${coreRegex}$`;
    }

    // Ignore leading and trailing instances of specific word boundary characters we may want to ignore
    return options?.excludedWordBoundaries && options.excludedWordBoundaries.length > 0
      ? `\\b(?<![${options.excludedWordBoundaries.join("")}])${coreRegex}\\b(?![${options.excludedWordBoundaries.join(
          ""
        )}])`
      : `\\b${coreRegex}\\b`;
  }

  /** Regular expression capturing all issue identifiers for the provided team identifiers. */
  public static identifierRegex(options?: IssueIdentifierMatchOptions) {
    return new RegExp(this.identifierRegexString(options), options?.flags ?? "i");
  }

  /** Generates a regular expression string that captures the issue identifier from a Linear issue URL.*/
  public static identifierUrlRegexString(options?: IssueIdentifierMatchAllOptions) {
    const url = Config.CLIENT_URL ?? "https://linear.app";
    const urlAsRegex = url.replace(/\./g, "\\.");
    return `${urlAsRegex}/[\\w-]+/issue/${this.identifierRegexCoreString(options)}(/[\\w-]+)?`;
  }

  /** Regular expression capturing all issue identifiers in urls. */
  public static identifierUrlRegex(options?: IssueIdentifierMatchOptions) {
    return new RegExp(this.identifierUrlRegexString(options), options?.flags ?? "i");
  }

  /**
   * Parse an identifier regular expression match into it's components.
   *
   * @param identifierMatch Regular expression match result of the identifier regular expression.
   */
  public static parseIdentifierMatch(
    identifierMatch: RegExpMatchArray | RegExpExecArray | string[]
  ): IssueIdentifierMatchResult | undefined {
    const [, rawIdentifier, teamKey, numberString] = identifierMatch;

    // Don't match LIN-0004 to LIN-4
    if (!rawIdentifier || !teamKey || !numberString || Number(numberString).toString().length !== numberString.length) {
      return;
    }

    const normalizedTeamKey = teamKey.toUpperCase();

    const number = Number(numberString);
    return {
      rawIdentifier,
      identifier: this.formatIssueIdentifier(normalizedTeamKey, number),
      teamKey: normalizedTeamKey,
      number,
    };
  }

  /**
   * Returns true if the provided string could plausibly represent an issue identifier. If you need strict matching
   * use the `identifierMatch` method.
   *
   * @param query The string to check for a possible issue identifier.
   * @returns True if the string could be an issue identifier.
   */
  public static possibleIdentifier(query: string) {
    return !!query.match(new RegExp(String.raw`^([A-Za-z]{2,${this.maxKeyLength}}[-\s])?\d{1,8}$`, "g"))?.join("-");
  }

  /**
   * Parses a string and returns a found issue identifier.
   * If no issue identifiers are found, returns undefined.
   *
   * NOTE: If options.exact is not set to true it's possible for this to match on UUIDs
   *
   * @param identifier String containing the issue identifier to match.
   * @param options
   * @returns A found issue identifier or undefined.
   */
  public static identifierMatch(identifier: string, options?: IssueIdentifierMatchOptions) {
    const match = identifier.match(this.identifierRegex(options));
    if (!match) {
      return;
    }

    return this.parseIdentifierMatch(match);
  }

  /**
   * Parses a string for one or more issue identifiers.
   *
   * @param identifier String containing one or more issue identifier to match.
   * @param options
   * @returns An array of found issue identifiers.
   */
  public static identifierMatchAll(identifier: string, options?: IssueIdentifierMatchAllOptions) {
    const matches = identifier.match(new RegExp(this.identifierRegexString(options), "gi"));
    if (!matches) {
      return [];
    }

    return matches
      .map(match => this.identifierMatch(match, { ...options, exact: true }))
      .filter(this.isIdentifierMatchResult);
  }

  private static isIdentifierMatchResult(
    result: IssueIdentifierMatchResult | undefined
  ): result is IssueIdentifierMatchResult {
    return !!result;
  }

  /**
   * Formats an issue identifier.
   *
   * Note: If the format of the identifier ever changes, the FieldResolver in the IssueResolver needs to be updated.
   * to match the new format.
   *
   * @param teamKey The team key.
   * @param number The issue number.
   * @returns The formatted issue identifier.
   */
  public static formatIssueIdentifier(teamKey: string, number: number) {
    return `${teamKey}-${number}`;
  }

  /**
   * Gets a previously used issue number for a team the issue is moving to. If number is returned it has to be validated
   * against current issue number sequence in the team, as team keys can be deleted and we could possibly get a number
   * that belonged to a different team here.
   *
   * @param previousIdentifiers The identifiers an issue has used in the past.
   * @param teamKey The key for the team the issue is moving to.
   * @returns A previously used issue number for that team, if any.
   */
  public static getPossiblePreviousIssueNumberForTeam(
    previousIdentifiers: string[],
    teamKey: string
  ): number | undefined {
    for (let i = previousIdentifiers.length - 1; i >= 0; i--) {
      const identifier = previousIdentifiers[i];
      if (identifier.startsWith(`${teamKey}-`)) {
        return Number(identifier.split("-")[1]);
      }
    }
    return;
  }
}
