import React from "react";
import styled from "styled-components";
import type { UseSelectGetItemPropsOptions, UseComboboxGetItemPropsOptions } from "downshift";
import { Text } from "@linear/orbiter/components/Text";
import { truncate } from "@linear/orbiter/styles/mixins";
import { Flex } from "@linear/orbiter/components/Flex";
import { CheckIcon } from "@linear/orbiter/icons/base/CheckIcon";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";

/**
 * Renders a selected icon for a combobox item. Is hidden when the item isn't
 * selected to maintain content width during item selection.
 */
export const SelectedComboboxListItemIcon = styled(CheckIcon)<{ $isSelected: boolean }>`
  fill: var(--figma-color-text);
  opacity: ${props => (props.$isSelected ? 1 : 0)};
  pointer-events: ${props => (props.$isSelected ? "auto" : "none")};
`;

/** Used for truncating the label & sublabel together. */
const TruncateDiv = styled.div`
  ${truncate()};
`;

/** Styling for the select list item. */
export const StyledPopoverMenuItem = styled.li<{ $isHighlighted?: boolean }>`
  background-color: ${props => (props.$isHighlighted ? "var(--figma-color-bg-hover)" : undefined)};
  border-radius: 4px;
  list-style: none;
  margin: 0 ${gridSpacePx(1)};
  padding: ${gridSpacePx(1.5)} ${gridSpacePx(2)};
`;

/** Shape of an item in a popover menu. */
export interface PopoverMenuItemShape<T> {
  /** If the menu item is destructive. */
  destructive?: boolean;
  /** Any styling for the item element. */
  image?: React.ReactNode;
  /** A unique key for comparing the item. */
  key: React.Key;
  /** Text for the option label. */
  label: string;
  /** Optional sub-label. */
  sublabel?: string;
  /** Optional prefix for the label. */
  prefix?: string;
  /** Value the option represents. */
  value: T;

  /**
   * TODO:
   * Implement this as a border around the list item without padding or
   * border radius.
   */
  divided?: boolean;
}

interface PopoverMenuItemProps<T> extends PopoverMenuItemShape<T> {
  /** Downshift props for the item. */
  itemProps: UseSelectGetItemPropsOptions<T> | UseComboboxGetItemPropsOptions<T>;
  /** If the item is currently highlighted. */
  isHighlighted?: boolean;
  /** If the item is the current selected one. */
  isSelected?: boolean;
}

/**
 * A menu item that displays in a popover menu. Used with selects,
 * multiselects, and comboboxes.
 */
export function PopoverMenuItem<T>(props: PopoverMenuItemProps<T>) {
  const { destructive, image, label, prefix, isSelected = false, sublabel, isHighlighted, itemProps } = props;

  return (
    <StyledPopoverMenuItem
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(itemProps as any)}
      $isHighlighted={isHighlighted}
      onClick={e => {
        e.stopPropagation();
        itemProps.onClick?.(e);
      }}
    >
      <Flex align="center" justify="space-between" gap={gridSpace(2)}>
        <Flex align="center" gap={gridSpace(2)} noMinWidth>
          {image && image}
          <Flex gap={gridSpace(1)} noMinWidth>
            <TruncateDiv>
              {prefix && <Text color="labelMuted">{prefix}</Text>}
              <Text className="mr-1" color={destructive ? "redBase" : undefined}>
                {label}
              </Text>
              {sublabel && <Text color="labelMuted">{sublabel}</Text>}
            </TruncateDiv>
          </Flex>
        </Flex>
        <SelectedComboboxListItemIcon $isSelected={isSelected} />
      </Flex>
    </StyledPopoverMenuItem>
  );
}
