import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { FigmaEditorType, type Issue } from "shared/types";
import { intersection } from "shared/utils/array";
import { Text } from "@linear/orbiter/components/Text";
import { Flex } from "@linear/orbiter/components/Flex";
import { LoadingIcon } from "@linear/orbiter/icons/base/LoadingIcon";
import { Button } from "@linear/orbiter/components/Button";
import { PlusIcon } from "@linear/orbiter/icons/figma/PlusIcon";
import { store } from "~/store";
import { uiFigma } from "~/store/UIFigma";
import { LinkedIssueListItem } from "~/components/LinkedIssueListItem";
import { useLinkedIssues } from "~/queries/useLinkedIssues";
import { gridSpace, gridSpacePx } from "~/styles/gridSpace";
import { useIssuesWithFigma } from "~/queries/useIssuesWithFigma";
import { Scrollable } from "~/components/Scrollable";
import { useLoadImage } from "~/hooks/useLoadImage";
import { LinkButton } from "~/components/LinkButton";
import { MotionFade, motionFadeAnimation } from "./MotionFade";
import { useSortedAndFilteredIssues, LinkedIssuesFilterAndSort } from "./LinkedIssuesFilterAndSort";

interface LinkedIssuesListProps {
  onAddIssue?: () => void;
  selectedIssue: Issue | null;
}

export const LinkedIssuesList = observer(function LinkedIssuesList_(props: LinkedIssuesListProps) {
  /**
   * Fetch all issues that mention the Figma file and update plugin issue
   * links across all pages in the Figma file.
   */
  const allIssuesWithFigmaRequest = useIssuesWithFigma(uiFigma.fileKey);

  const linkedIssuesRequest = useLinkedIssues(store.issueLinks);
  const [selectedIssue, setSelectedIssue] = useState<Issue | null>(props.selectedIssue);
  const selectedIssueLinks = selectedIssue ? store.issueLinks[selectedIssue.id] : null;

  const hasIssueLinks = Object.keys(store.issueLinks).length > 0;
  const hasLinkedIssues = Object.keys(store.linkedIssues).length > 0;

  // Shared props & state between this parent component, the button in the
  // empty list that clears filters, and the <LinkedIssuesFilterAndSort>
  // component.
  const [issuesToDisplay, onSortFilterChange, { selectedFilters, selectedSorter }] = useSortedAndFilteredIssues(
    Object.values(store.linkedIssues) as Issue[],
    store.user
  );

  let emptyImageSrc: string | null = null;
  if (uiFigma.editorType === FigmaEditorType.Dev) {
    emptyImageSrc =
      uiFigma.editorTheme === "light" ? "/images/empty-list/inspect-light.png" : "/images/empty-list/inspect-dark.png";
  } else {
    emptyImageSrc =
      uiFigma.editorTheme === "light" ? "/images/empty-list/edit-light.png" : "/images/empty-list/edit-dark.png";
  }

  // Load images manually before showing fade-in animation
  const hasLoadedEmptyImage = useLoadImage(emptyImageSrc);

  useEffect(() => {
    const selectedIssueHasSelection = selectedIssueLinks?.some(issueLink => {
      // If the page is selected and the selected linked issue is linked to the
      // page, don't clear the selection
      const selectedAsPage = uiFigma.selection.isPage && issueLink.node.type === "PAGE";

      if (selectedAsPage) {
        return true;
      }

      return uiFigma.selection.nodes.find(selectedNode => {
        return issueLink.node.id === selectedNode.id;
      });
    });

    if (!selectedIssueHasSelection && selectedIssue) {
      setSelectedIssue(null);
    }
  }, [selectedIssueLinks, uiFigma.selection]);

  if (allIssuesWithFigmaRequest.error || linkedIssuesRequest.error) {
    return (
      <EmptyPanelContent column grow={1} gap={gridSpace(2)}>
        {allIssuesWithFigmaRequest.error && <Text>{allIssuesWithFigmaRequest.error.toString()}</Text>}
        {linkedIssuesRequest.error && <Text>{linkedIssuesRequest.error.toString()}</Text>}
      </EmptyPanelContent>
    );
  }

  // When there are linked issues stored in the plugin but haven't been fetched
  // yet
  const isLoadingLinkedIssues = hasIssueLinks && !hasLinkedIssues && linkedIssuesRequest.isLoading;

  // Only show the loading spinner when there are issues stored in plugin
  // storage but we haven't fetched them yet
  const showLoading = store.initializingIssueLinks || isLoadingLinkedIssues;

  const LinkedIssues = issuesToDisplay.map(issue => {
    if (!issue) {
      return null;
    }

    const issueId = issue.id;
    const linkedIssue = store.linkedIssues[issueId];
    const issueLinks = store.issueLinks[issueId];

    if (!linkedIssue || !issueLinks || issueLinks.length === 0) {
      return null;
    }

    const isActivelySelected = selectedIssue?.id === issueId;

    // If the issue isn't actively selected and the current canvas selection
    // includes some of the linked nodes.
    const isPassivelySelected =
      !isActivelySelected &&
      intersection(
        uiFigma.selection.nodes,
        issueLinks,
        (selectedNode, issueLink) => selectedNode.id === issueLink.node.id
      ).length > 0;

    return (
      <motion.div
        className="linked-issue-list-item-motion"
        key={issueId}
        exit={{ overflow: "hidden", height: 0 }}
        transition={{ duration: 0.25 }}
      >
        <LinkedIssueListItem
          isActivelySelected={isActivelySelected}
          isHighlighted={props.selectedIssue?.id === issueId}
          isPassivelySelected={isPassivelySelected}
          issueLinks={issueLinks}
          key={issueId}
          linkedIssue={linkedIssue}
          onClick={clickedIssue => {
            setSelectedIssue(clickedIssue);
          }}
        />
      </motion.div>
    );
  });

  return (
    <AnimatePresence mode="popLayout" key={uiFigma.currentPage.id}>
      {showLoading ? (
        <MotionList key="loading-linked-issues-list">
          <Flex className="m-4" justify="center" gap={gridSpace(2)} grow={1}>
            <LoadingIcon />
            <Text color="labelMuted">Loading…</Text>
          </Flex>
        </MotionList>
      ) : hasLinkedIssues ? (
        <MotionList key="linked-issues-list">
          <Scrollable
            className="h-full"
            column
            grow={1}
            onClick={() => {
              // If the user clicks on the panel, clear the selected linked issue
              setSelectedIssue(null);
            }}
          >
            <StyledListHeader>
              <Text color="labelMuted">On this page</Text>
              <LinkedIssuesFilterAndSort
                onSortFilterChange={onSortFilterChange}
                selectedFilters={selectedFilters}
                selectedSorter={selectedSorter}
              />
            </StyledListHeader>
            <Scrollable column grow={1}>
              {issuesToDisplay.length > 0 ? (
                <AnimatePresence initial={false}>{LinkedIssues}</AnimatePresence>
              ) : (
                <EmptyFilteredListFlex>
                  <Text color="labelMuted">No issues matching filters</Text>
                  <EmptyFilteredListLinkButton
                    onClick={() => {
                      // Clear current filters without changing the sort method
                      onSortFilterChange(undefined, []);
                    }}
                  >
                    Clear filters
                  </EmptyFilteredListLinkButton>
                </EmptyFilteredListFlex>
              )}
            </Scrollable>
          </Scrollable>
        </MotionList>
      ) : (
        <MotionList
          key="empty-linked-issues-list"
          animate={hasLoadedEmptyImage ? motionFadeAnimation.animate : {}}
          transition={{
            delay: 0.075,
          }}
        >
          <EmptyImage src={emptyImageSrc} />
          <EmptyPanelContent column>
            <Flex column gap={gridSpace(3)}>
              <Text align="center">Select a frame or a section and create an issue or link to an existing one</Text>
              <Text color="labelMuted" align="center">
                Figma attachments will be listed in the Linear issue
              </Text>
            </Flex>
            <EmptyAddButtonWrapper>
              <EmptyAddButton
                onClick={() => {
                  props.onAddIssue?.();
                }}
              >
                <PlusIcon />
                <Text variant="regularPlus" color="controlLabel">
                  Link or create
                </Text>
              </EmptyAddButton>
            </EmptyAddButtonWrapper>
          </EmptyPanelContent>
        </MotionList>
      )}
    </AnimatePresence>
  );
});

const MotionList = styled(MotionFade)`
  height: 100%;
`;

const StyledListHeader = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: ${gridSpacePx(2)} var(--panel-x-padding);
`;

const EmptyPanelContent = styled(Flex)`
  margin-left: auto;
  margin-right: auto;
  max-width: ${gridSpacePx(60)};
  padding-left: var(--panel-x-padding);
  padding-right: var(--panel-x-padding);
`;

const EmptyFilteredListFlex = styled(Flex)`
  align-items: center;
  flex-direction: column;
  gap: ${gridSpacePx(2)};
  margin-top: ${gridSpacePx(23)};
  padding-left: var(--panel-x-padding);
  padding-right: var(--panel-x-padding);
`;

const EmptyFilteredListLinkButton = styled(LinkButton)`
  color: var(--color-accent);
`;

const EmptyImage = styled.img`
  display: block;
  margin-bottom: ${gridSpacePx(12)};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${gridSpacePx(13)};
  width: ${gridSpacePx(54)};
`;

const EmptyAddButtonWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: ${gridSpacePx(6)};
  width: auto;
`;

const EmptyAddButton = styled(Button)`
  padding-left: 0;
`;
