/** A template string fragment for fetching Teams across the application. */
export const TeamFragment = `
  color
  icon
  id
  key
  name
  displayName
  private
`;
