import type { ColorConverter } from "./ColorConverter";

/**
 * Returns the color format to use based on the browser's support for LCH and P3 colors.
 */
export const getColorFormat = (): ColorConverter.ColorFormat =>
  typeof window === "undefined" || typeof window.CSS === "undefined"
    ? "RGB"
    : window.CSS.supports("color", "lch(0% 0 0)")
    ? "LCH"
    : window.CSS.supports("color", "color(display-p3 0 0 0)")
    ? "P3"
    : "RGB";
